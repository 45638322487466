import {
  CompanySellerState,
  ManualTransactionState,
  PersonalDetailsWithAddressState,
} from "@schema";
import { User } from "../../pages/UserDetails/types";
import { Company } from "../../../types";
import { initialManualTransactionPropertyDetailsStateObj } from "./initialDetails";

export const createRepresentativeValues = (
  user: User,
): PersonalDetailsWithAddressState => ({
  firstName: user.first_name || "",
  middleName: user.middle_name || "",
  lastName: user.last_name || "",
  email: user.email,
  mobileNumber: user.profile?.phone_number || "",
  correspondenceAddress:
    user.profile?.correspondence_address ||
    initialManualTransactionPropertyDetailsStateObj.propertyAddress,
  personId: user._id || "",
  termsAndConditions: true,
  primaryCustomer: true,
});

export const createCompanySellerValues = (
  company: Company,
): CompanySellerState => ({
  name: company.name,
  companyNumber: company.company_number,
  address: company.address,
});

export const isEmptyTransaction = (transaction: ManualTransactionState) => {
  return (
    !transaction.property ||
    !transaction.property.askingPrice ||
    Object.values(transaction.property.propertyAddress).every((value) => !value)
  );
};
