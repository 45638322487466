import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

type EstateAgentsApiResponse =
  paths["/api/estate-agent-brand"]["get"]["responses"]["200"]["content"]["application/json"];

export const findAllEstateAgentBrands =
  async (): Promise<EstateAgentsApiResponse> => {
    try {
      const response = await api.get("/estate-agent-brand");
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
