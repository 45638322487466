import * as Sentry from "@sentry/react";

import { api } from ".";
import { paths } from "@shared/types.d";

export type UpdateUserRequest =
  paths["/api/users"]["put"]["requestBody"]["content"]["application/json"];

export type UpdateUserResponse =
  paths["/api/users"]["put"]["responses"]["200"]["content"]["application/json"];

export const updateUser = async (
  updatedUser: UpdateUserRequest,
): Promise<UpdateUserResponse> => {
  try {
    const response = await api.put("/users", updatedUser);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Update user failed");
  }
};
