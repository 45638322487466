import * as Sentry from "@sentry/react";

import { TransactionResponse } from "@shared/types";
import { api } from ".";
import { paths } from "@shared/types.d";

export type UpdateTransactionRequest =
  paths["/api/transaction/{id}"]["put"]["requestBody"]["content"]["application/json"];

export const updateTransaction = async (
  updatedTransaction: UpdateTransactionRequest,
): Promise<TransactionResponse> => {
  try {
    const response = await api.put(
      `/transaction/${updatedTransaction._id}`,
      updatedTransaction,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Update transaction failed");
  }
};
