import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../store/auth/AuthContext";
import { handleSignOut } from "@utils";
import { useAuthStore } from "../../store/auth/auth";
import Typography from "@/foundation/Typography/Typography";

import Logo from "/logos/horizontal.svg";

export const Nav = () => {
  const navigate = useNavigate();
  const { userData, actions: authStoreActions } = useAuthStore();
  const authStoreContext = useContext(AuthContext);
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    if (userData?.active && userData?.email) {
      setIsSignedIn(true);
    }
  }, [userData, isSignedIn]);

  return (
    <div
      className="w-full bg-brand-heavy-warmth-75 h-20 flex justify-between items-center px-[16px] md:px-[40px]"
      data-testid="nav"
    >
      <img src={Logo} width={140} />

      <div className="flex flex-row items-center">
        {isSignedIn && (
          <>
            <Typography
              type="p"
              variant="base"
              weight="semi-bold"
              className="text-brand-heavy-teal-100/50 mr-[12px] hidden md:inline-block"
            >
              {userData?.email}
            </Typography>

            <button
              className="shrink-0 px-[8px] flex items-center"
              onClick={() => navigate("/welcome-back")}
            >
              <Typography
                type="p"
                variant="base"
                weight="bold"
                className="text-brand-heavy-teal-100"
              >
                All Transactions
              </Typography>
            </button>

            {userData?.type === "admin" && (
              <button
                className="shrink-0 px-[8px] flex items-center"
                onClick={() => navigate("/admin")}
              >
                <Typography
                  type="p"
                  variant="base"
                  weight="bold"
                  className="text-brand-heavy-teal-100"
                >
                  Admin
                </Typography>
              </button>
            )}

            <button
              className="shrink-0 px-[8px] flex items-center"
              onClick={() =>
                handleSignOut(navigate, authStoreActions, authStoreContext)
              }
            >
              <Typography
                type="p"
                variant="base"
                weight="bold"
                className="text-brand-heavy-teal-100 mr-[8px]"
              >
                Sign Out
              </Typography>
            </button>
          </>
        )}
      </div>
    </div>
  );
};
