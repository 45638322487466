import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

export type CreateActiveCustomerResponse =
  paths["/api/users/active-customer"]["post"]["responses"]["201"]["content"]["application/json"];

export type CreateActiveCustomerRequest =
  paths["/api/users/active-customer"]["post"]["requestBody"]["content"]["application/json"];

export const createActiveCustomer = async (
  newUser: CreateActiveCustomerRequest,
): Promise<CreateActiveCustomerResponse> => {
  try {
    const response = await api.post(`/users/active-customer`, newUser);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Error creating active customer");
  }
};
