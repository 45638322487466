import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

import { api } from "../../../service/ApiController";
import { paths } from "@shared/types.d";

type LawFirmResponse =
  paths["/api/law-firm/{id}"]["get"]["responses"]["200"]["content"]["application/json"];

export const findLawFirm = async (id: string): Promise<LawFirmResponse> => {
  try {
    const response = await api.get(`/law-firm/${id}`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};
