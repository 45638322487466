import { Navigate, useSearchParams } from "react-router-dom";
import { useState } from "react";

import { ApiController } from "../../service/ApiController";
import { Notifications } from "../../components/Notifications";
import { SignIn as SignInFormContainer } from "../../components/SignIn/SignIn/SignIn";
import { SignInSuccess } from "../../components/SignIn/SignInSuccess/SignInSuccess";
import { useAuthStore } from "../../store/auth/auth";
import { useLoginStore } from "../../store/Login/login";

import { SignInType } from "@schema";
import { useLastCustomerSignIn } from "@utils";

import Logo from "../../assets/logo-horizontal.svg";

export const SignIn = () => {
  const { userData } = useAuthStore();
  const [searchParams] = useSearchParams();
  const { updateField, isRegistered, setIsRegistered } = useLoginStore();
  const [errorMessage, setErrorMessage] = useState("");
  const [lessThan5Min, setLessThan5Min] = useState<boolean>(false);

  useLastCustomerSignIn(setIsRegistered, setLessThan5Min);

  const handleSignIn = async (values: SignInType) => {
    try {
      setErrorMessage("");
      updateField("email", values.email);

      const preventEmail = searchParams.get("preventEmail");
      await ApiController.signIn(values.email, !!preventEmail);
      setIsRegistered(true);

      const currentTime = Date.now();
      localStorage.setItem("lastClickTime", String(currentTime));
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
        console.error(error.message);
      } else {
        setErrorMessage(
          "An unexpected error has occured. Please try again later.",
        );
      }
    }
  };

  if (userData) {
    return <Navigate to={`/welcome-back`} replace />;
  }

  return (
    <div className="bg-brand-heavy-teal-100 bg-[url('/src/assets/house-texture-clipped.svg')] bg-no-repeat bg-left-bottom">
      <Notifications />

      <div className="flex flex-col lg:flex-row md:justify-center items-center p-[12px] md:p-[24px] min-h-screen">
        <div
          className="w-full flex flex-col lg:flex-row justify-center items-center"
          data-testid="sign-in-description"
        >
          <div
            className="bg-brand-medium-sand-50 rounded-2xl w-full max-w-[657px] flex flex-col justify-center items-center py-[50px] md:py-[75px] px-[25px] md:px-[125px]"
            data-testid="sign-in-container"
          >
            <img src={Logo} className="mb-[36px]" />

            {isRegistered ? (
              <SignInSuccess
                handleSignIn={handleSignIn}
                lessThan5Min={lessThan5Min}
                setLessThan5Min={setLessThan5Min}
              />
            ) : (
              <SignInFormContainer
                handleSignIn={handleSignIn}
                errorMessage={errorMessage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
