import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

import { api } from "../../../service/ApiController";

export const deleteEstateAgentBrand = async (id: string): Promise<void> => {
  try {
    await api.delete(`/estate-agent-brand/${id}`);
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data?.message);
    }
    throw error;
  }
};
