import { useNavigate, useLocation } from "react-router-dom";

export const useSetQueryParam = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const setQueryParam = (param: string, value: string) => {
    const searchParams = new URLSearchParams(location.search);

    // Set or update the query parameter
    searchParams.set(param, value);

    // Update the URL without reloading the page
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  return setQueryParam;
};
