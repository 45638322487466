import { z } from "zod";

import {
  CustomMetadataSchema,
  PaymentIntentResponseSchema,
  ProductSchema,
} from "./schemas";
import { TextInputIconProps } from "@/foundation/Icons/Icons";
import {
  LawyerGroups,
  Order,
  Seller,
  PurchaseIntent,
} from "../frontend/src/admin/pages/TransactionDetails/types";
import { Address } from "../frontend/src/admin/pages/UserDetails/types";
import { UserType as User } from "../frontend/src/store/auth/auth";
import { mortgageProvidersEnum } from "../frontend/src/components/Form/DetailsForm/Fields";
import { EstateAgent } from "../frontend/src/types";

export type CustomMetadata = z.infer<typeof CustomMetadataSchema>;

export type StripeCustomerId = string;

export type StripeCustomer = Partial<{
  id: StripeCustomerId;
}>;

export type PaymentIntentResponse = z.infer<typeof PaymentIntentResponseSchema>;

export type Role = "sell" | "buy";

export enum Roles {
  Seller = "Seller",
  Buyer = "Buyer",
}

export enum UserType {
  Customer = "customer",
  Agent = "agent",
  Solicitor = "solicitor",
  Admin = "admin",
}

export type Card<T> = {
  icon?: TextInputIconProps | undefined;
  whiteIcon?: TextInputIconProps | undefined;
  option: T;
  label: string;
  message: string;
};

export enum Tenure {
  Freehold = "Freehold",
  Leasehold = "Leasehold",
  ShareOfFreehold = "Share Of Freehold",
  Other = "Other / Not Sure",
}

export enum RelationshipToSale {
  IndividualOwner = "individual_owner",
  Representative = "representative",
}

export type AssignedSelected = {
  selected: boolean;
  value: string;
  error: string;
};

export type Product = z.infer<typeof ProductSchema>;

export type PropertyResponse = {
  _id: string;
  address: Address;
  tenure: Tenure;
};

interface DateSolictorGroupsAssigned {
  assigned_at: Date;
  date_legal_started: Date;
}

export type TransactionResponse = {
  _id: string;
  purchase_intent: PurchaseIntent[];
  sellers: Seller[];
  orders: Order[];
  property_id: PropertyResponse;
  has_mortgage: boolean;
  onward_purchase: boolean;
  asking_price: number;
  createdAt: string;
  date_solicitor_groups_assigned?: DateSolictorGroupsAssigned;
  lawyer_group?: LawyerGroups;
  estate_agent?: EstateAgent;
  type: "resale" | "development";
};

type OwnershipType = "Sole" | "Joint";

type BooleanPlusNotSure = "yes" | "no" | "not_sure";

type RelationshipType = "Parent" | "Other Family" | "Other";

type CustomerType = "individual_owner" | "representative";

export interface Giftor {
  amount: number;
  correspondence_address: Address;
  email: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  person_id: string;
  phone_number: string;
  relationship: RelationshipType;
}

export interface PurchaseIntentBuyer {
  user: User;
  terms_and_conditions: boolean;
  customer_type: CustomerType;
  primary_customer: boolean;
  giftors: Giftor[];
}

export interface PurchaseIntentResponse {
  _id: string;
  agreed_price: number;
  buyers: PurchaseIntentBuyer[];
  first_time_buyer: boolean;
  funding: {
    directors_loans_dividends: boolean;
    divorce_settlement: boolean;
    gifts: BooleanPlusNotSure;
    inheritance: boolean;
    investment_portfolio: boolean;
    other_funding?: string;
    pension_lump_sum_withdrawal: boolean;
    remortgage: boolean;
    sale_of_shares: boolean;
    savings_from_employment: boolean;
  };
  mortgage_provider: mortgageProvidersEnum;
  mortgage_required_for_purchase: BooleanPlusNotSure;
  other_funding_comments?: string;
  ownership_type: OwnershipType;
  property_id: string;
  purchase_dependent_on_sale: boolean;
  significant_dates?: {
    sale_agreed?: string;
    exchanged?: string;
    aborted?: string;
    completed?: string;
  };
}

export interface PrimaryBuyerLocalState {
  personId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  termsAndConditions: boolean;
  primaryCustomer: boolean;
  customerType: CustomerType;
  correspondenceAddress: Address;
  giftors: Giftor[];
}
