import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from ".";

type FetchUserResponse =
  paths["/api/users/{id}"]["get"]["responses"]["200"]["content"]["application/json"];

export const fetchUser = async (id: string): Promise<FetchUserResponse> => {
  try {
    const response = await api.get(`/users/${id}`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Fetch user failed");
  }
};
