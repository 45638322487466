import Typography from "@/foundation/Typography/Typography";
import { ManualAddressForm } from "./ManualAddressForm";

interface ManualAddressProps {
  isManualAddressOpen: boolean;
  setIsManualAddressOpen: (value: boolean) => void;
  namespace: string;
}

export const ManualAddress = ({
  isManualAddressOpen,
  setIsManualAddressOpen,
  namespace,
}: ManualAddressProps) => (
  <>
    {isManualAddressOpen ? (
      <div className="flex flex-col w-full md:w-[320px] space-y-[8px] mt-[24px]">
        <ManualAddressForm namespace={namespace} />
      </div>
    ) : (
      <button
        className="underline cursor-pointer text-brand-heavy-teal-75 my-[16px]"
        onClick={() => setIsManualAddressOpen(true)}
        type="button"
      >
        <Typography
          variant="base"
          weight="regular"
          type="span"
          className="text-brand-heavy-teal-75"
        >
          Enter address manually
        </Typography>
      </button>
    )}
  </>
);
