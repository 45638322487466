import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Table, TableRow } from "@/components/Table/Table";
import { ApiController } from "../../../service/ApiController";
import Typography from "@/foundation/Typography/Typography";
import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { EstateAgentsFilterModal } from "../../components/EstateAgentsFilterModal/EstateAgentsFilterModal";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import Icons from "@/foundation/Icons/Icons";
import { Pagination } from "../../components/Pagination/Pagination";
import { ItemsPerPage } from "../../components/Pagination/ItemsPerPage";
import TextInput from "@/components/Input/TextInput";
import { EstateAgent } from "../../../types";
import { Button } from "@/components/Button/Button";

interface EstateAgentRow {
  brandAndOffice: string;
  officePhoneNumber?: string;
  primaryValuerFullName: string;
  primaryValuerEmail: string;
  primaryValuerPhoneNumber: string;
}

export const EstateAgents = () => {
  const navigate = useNavigate();

  const [estateAgents, setEstateAgents] = useState<EstateAgent[]>([]);
  const [brandCounts, setBrandCounts] = useState<
    { brand: string; count: number }[] | undefined
  >([{ brand: "", count: 0 }]);
  const [brandFilterOption, setBrandFilterOption] = useState<string>();
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalEstateAgents, setTotalEstateAgents] = useState(0);
  const [totalFilteredEstateAgents, setTotalFilteredEstateAgents] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [displayedEstateAgents, setDisplayedEstateAgents] =
    useState<TableRow<EstateAgentRow>[]>();

  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  useEffect(() => {
    async function getAllEstateAgents() {
      setLoading(true);
      setError("");

      try {
        const data = await ApiController.findAllEstateAgents({
          brand: brandFilterOption,
          searchValue: debouncedSearchValue,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage * currentPage,
        });
        setLoading(false);
        setEstateAgents(data.items);
        setTotalEstateAgents(data.totalItems);
        setTotalFilteredEstateAgents(data.totalFilteredItems);
        setBrandCounts(data.brandCounts);

        const totalPages = Math.ceil(totalFilteredEstateAgents / itemsPerPage);
        setTotalPages(totalPages);
      } catch (error) {
        if (error instanceof Error) {
          setError("There was an error while fetching estate agents");
          setLoading(false);
        }
      }
    }
    getAllEstateAgents();
  }, [
    currentPage,
    brandFilterOption,
    itemsPerPage,
    totalFilteredEstateAgents,
    debouncedSearchValue,
  ]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    // Reset to the first page on filter change:
    setCurrentPage(1);
  }, [brandFilterOption, debouncedSearchValue]);

  useEffect(() => {
    const getDataRows = (
      estateAgents: EstateAgent[],
    ): TableRow<EstateAgentRow>[] => {
      return estateAgents
        .map((estateAgent) => {
          const { brand, office, valuers } = estateAgent;
          const primaryValuer = valuers.find((valuer) => valuer.primary_valuer);

          return {
            data: {
              brandAndOffice: `${brand} - ${office.name}` || "",
              officePhoneNumber: office.phone_number || "",
              primaryValuerFullName:
                `${primaryValuer?.first_name} ${primaryValuer?.last_name}` ||
                "",
              primaryValuerEmail: primaryValuer?.email || "",
              primaryValuerPhoneNumber: primaryValuer?.phone_number || "",
            },
            onClick: () => navigate(`/admin/estate-agents/${estateAgent._id}`),
          };
        })
        .sort((a, b) =>
          a.data.brandAndOffice.localeCompare(b.data.brandAndOffice),
        );
    };

    setDisplayedEstateAgents(getDataRows(estateAgents));
  }, [setDisplayedEstateAgents, estateAgents, navigate]);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [searchValue]);

  const columns = [
    { key: "brandAndOffice", title: "Office Name" },
    { key: "officePhoneNumber", title: "Office Phone Number" },
    { key: "primaryValuerFullName", title: "Primary Valuer Name" },
    { key: "primaryValuerEmail", title: "Primary Valuer Email" },
    { key: "primaryValuerPhoneNumber", title: "Primary Valuer Phone Number" },
  ];

  return (
    <>
      <div className="flex">
        <AdminTitle title="Estate Agents" />

        <Button
          className="mt-[20px] md:mt-[40px] mr-[20px]"
          variant="secondary"
          onClick={() => navigate("/admin/estate-agent-brands")}
        >
          Brands
        </Button>
        <Button
          className="mt-[20px] md:mt-[40px]"
          variant="primary"
          onClick={() => navigate("/admin/estate-agents/add")}
        >
          Add estate agent
        </Button>
      </div>

      {error && (
        <Typography variant="lg" weight="bold" type="p">
          {error}
        </Typography>
      )}

      {!error && (
        <div>
          <div className="flex flex-col md:flex-row justify-between mb-[12px] w-full">
            {/* Left Section: ItemsPerPage */}
            <div className="md:w-auto w-full">
              <ItemsPerPage setItemsPerPage={setItemsPerPage} />
            </div>

            {/* Right Section: Filter and Search */}
            <div className="flex flex-col md:flex-row-reverse items-center md:justify-end md:space-x-[16px] md:space-x-reverse md:w-auto w-full mt-[12px] md:mt-0">
              {/* Search Bar */}
              <div className="md:w-auto w-full">
                <TextInput
                  showIcon
                  variant="enabled"
                  label="Search"
                  name="search"
                  icon="Search"
                  className="w-full md:w-[320px]"
                  value={searchValue}
                  onChange={(val) => setSearchValue(val.target.value)}
                />
              </div>

              {/* Filter */}
              <div className="relative flex flex-row-reverse md:flex-row items-center justify-end md:w-auto w-full mt-[12px] md:mt-0">
                {brandFilterOption !== undefined && (
                  <div className="flex items-center bg-brand-heavy-teal-100 px-[16px] py-[4px] rounded-[100px] text-ui-white-100 mx-[16px]">
                    <Typography>{brandFilterOption}</Typography>
                    <button
                      className="ml-[8px]"
                      onClick={() => setBrandFilterOption(undefined)}
                    >
                      <Icons iconName="Close" colour="complement" />
                    </button>
                  </div>
                )}

                <button
                  className="flex items-center justify-center border h-[64px] w-[64px] rounded-lg text-brand-heavy-teal-100 border-brand-heavy-teal-100 hover:text-brand-heavy-teal-75"
                  onClick={() => setFilterModalOpen(true)}
                >
                  <Icons iconName="Filter" size="24" />
                </button>

                {filterModalOpen && (
                  <div className="top-[68px] absolute">
                    <EstateAgentsFilterModal
                      setBrandFilterOption={setBrandFilterOption}
                      brandFilterOption={brandFilterOption}
                      brandCounts={brandCounts}
                      totalEstateAgents={totalEstateAgents}
                      onCloseModal={() => setFilterModalOpen(false)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <Table columns={columns} rows={displayedEstateAgents} />

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            itemsPerPage={itemsPerPage}
            totalFilteredItems={totalFilteredEstateAgents}
            handlePageChange={handlePageChange}
          />
        </div>
      )}

      {loading && <FullPageLoader />}
    </>
  );
};
