import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

type EstateAgentBrandBody =
  paths["/api/estate-agent-brand"]["post"]["requestBody"]["content"]["application/json"];

type EstateAgentBrandApiResponse =
  paths["/api/estate-agent-brand"]["post"]["responses"]["201"]["content"]["application/json"];

export const createEstateAgentBrand = async (
  body: EstateAgentBrandBody,
): Promise<EstateAgentBrandApiResponse> => {
  try {
    const response = await api.post("/estate-agent-brand", body);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
