import * as Sentry from "@sentry/react";

import { api } from ".";

export const updateReferralCodeInTransaction = async (
  transactionId: string,
  referralCode: string,
): Promise<void> => {
  try {
    const response = await api.post(
      `/transaction/${transactionId}/referral-code`,
      { referralCode },
    );

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Updating the referral code on the transaction failed");
  }
};
