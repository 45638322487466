import { Button } from "@/components/Button/Button";

interface AddPersonnelProps {
  buttonLabel: string;
  onButtonClick: (value: boolean) => void;
}

export const AddPersonnel = ({
  buttonLabel,
  onButtonClick,
}: AddPersonnelProps) => (
  <div className="min-h-max flex flex-col min-w-[300px] flex-grow w-full md:w-[calc(33.33%-1.25rem)] rounded-xl shadow-questionType border border-ui-black-10">
    <div className="h-[64px] bg-brand-heavy-warmth-25 flex items-center justify-between rounded-t-lg px-[32px]"></div>
    <div className="min-h-max grow flex items-center justify-center p-[20px]">
      <Button variant="secondary" onClick={() => onButtonClick(true)}>
        {buttonLabel}
      </Button>
    </div>
  </div>
);
