import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { Table, TableProps } from "../Table/Table";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import { AddPaymentDetails } from "./AddPaymentDetails";

export const PaymentDetailsBlock = () => {
  const { stripeTransactionId, setStripeTransactionId } =
    useTransactionsGroupContext();

  const [fields, setFields] = useState<TableProps[]>([]);

  useEffect(() => {
    if (stripeTransactionId) {
      const updatedFields = [
        { label: "Stripe Transaction Id", value: stripeTransactionId },
      ];
      setFields(updatedFields);
    }
  }, [stripeTransactionId]);

  return (
    <Card title="Payment Details" backgroundColour="bg-brand-heavy-warmth-25">
      {stripeTransactionId ? (
        <div className="w-full">
          <Card
            titleColor="brand-heavy-teal-100"
            titleBackgroundColor="brand-heavy-warmth-50"
            title="Payment Details"
            titleRightContent={
              <button onClick={() => setStripeTransactionId!("")}>
                Delete
              </button>
            }
          >
            <div>
              {fields.map(({ value, label }) => (
                <div key={label} className="flex jusify-top">
                  <Table key={value as string} label={label} value={value} />
                </div>
              ))}
            </div>
          </Card>
        </div>
      ) : (
        <AddPaymentDetails />
      )}
    </Card>
  );
};
