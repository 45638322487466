import * as Sentry from "@sentry/react";

import { api } from "../../../service/ApiController";
import { paths } from "@shared/types.d";
import { getSearchParamsStringFromObject } from "@utils";

export type LawyerGroupsResponse =
  paths["/api/lawyer-group"]["get"]["responses"]["200"]["content"]["application/json"];

type LawyerGroupQueryParams =
  paths["/api/lawyer-group"]["get"]["parameters"]["query"];

export const findAllLawyerGroups = async (
  params?: LawyerGroupQueryParams,
): Promise<LawyerGroupsResponse> => {
  try {
    const response = await api.get(
      `/lawyer-group?${getSearchParamsStringFromObject(params)}`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);

    throw new Error("There was an error fetching lawyer groups");
  }
};
