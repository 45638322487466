import { useState } from "react";

import { EstateAgent } from "../../../types";
import { EstateAgentValuerModal } from "../Modals/EstateAgentValuerModal";
import { useEstateAgentContext } from "../../context/EstateAgentProvider";
import { PersonnelDetails } from "./PersonnelDetails";

type Valuer = EstateAgent["valuers"][0];

export interface EditValuerProps {
  valuer: Valuer;
  valuers: Valuer[];
  index: number;
}

export const EditValuer = ({ valuer, valuers, index }: EditValuerProps) => {
  const { updateEstateAgent, estateAgent } = useEstateAgentContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onUpdateValuer = async (updatedValuer: Valuer) => {
    setErrorMessage("");

    const { error } = await updateEstateAgent!({
      ...estateAgent!,
      valuers:
        valuers.map((valuer, i) =>
          i === index
            ? updatedValuer
            : { ...valuer, primary_valuer: !updatedValuer.primary_valuer },
        ) || [],
    });

    if (error) {
      setErrorMessage(error);
    } else {
      setIsModalOpen(false);
    }
  };

  const onDeleteValuer = async () => {
    setErrorMessage("");

    const newValuers = valuers.reduce(
      (acc, valuer, i) => {
        if (i !== index) {
          acc.push({ ...valuer, primary_valuer: true });
        }
        return acc;
      },
      [] as EstateAgent["valuers"],
    );

    const { error } = await updateEstateAgent!({
      ...estateAgent!,
      valuers: newValuers,
    });

    if (error) {
      setErrorMessage(error);
    } else {
      setIsModalOpen(false);
    }
  };

  return (
    <div
      className="min-w-[300px] flex-grow w-full md:w-[calc(33.33%-1.25rem)]"
      key={`valuer-card-${index}`}
    >
      <PersonnelDetails
        onEdit={() => {
          setErrorMessage("");
          setIsModalOpen(true);
        }}
        title={`${valuer.primary_valuer ? "Primary" : "Secondary"} valuer`}
        personnelDetails={valuer}
        keyPrefix={`valuer-${index}`}
      />
      {isModalOpen && (
        <EstateAgentValuerModal
          initialValues={valuer}
          errorMessage={errorMessage}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(updatedValuer) => {
            onUpdateValuer(updatedValuer);
          }}
          showDeleteButton
          disableDeleteButton={valuers.length === 1}
          onDelete={onDeleteValuer}
        />
      )}
    </div>
  );
};
