import { useState } from "react";

import ErrorText from "@/foundation/Typography/ErrorText";
import { Modal } from "../../../components/Modal/Modal";
import { ApiController } from "../../../service/ApiController";
import { EstateAgentBrand } from "../../../types";
import { Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";
import { Input } from "../../../components/Formik/Input/Input";
import { Button } from "@/components/Button/Button";

interface EstateAgentBrandModalProps {
  onClose: () => void;
  onSaved: () => void;
  brand?: EstateAgentBrand;
}

const brandForm = z.object({
  brandName: z.string().min(1),
});

type BrandForm = z.infer<typeof brandForm>;

export const EstateAgentBrandModal = ({
  onClose,
  onSaved,
  brand,
}: EstateAgentBrandModalProps) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onSaveBrand = async (newBrand: BrandForm) => {
    try {
      setError("");
      setLoading(true);

      if (brand) {
        await ApiController.updateEstateAgentBrand(brand._id, newBrand);
      } else {
        await ApiController.createEstateAgentBrand(newBrand);
      }

      onSaved();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("There was an errror saving the brand");
      }
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (id: string) => {
    try {
      setError("");
      setLoading(true);

      await ApiController.deleteEstateAgentBrand(id);
      onSaved();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("There was an errror deleting the brand");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{ brandName: brand?.brandName || "" }}
      validate={withZodSchema(brandForm)}
      onSubmit={onSaveBrand}
    >
      {({ handleSubmit, values }) => (
        <Modal title="Brand" hideSaveButton cancelButtonFunction={onClose}>
          <>
            <div className="mb-[8px]">
              <Input fieldName="brandName" label="Brand Name" />
            </div>
            <div className="mt-[32px]">
              {error && <ErrorText>{error}</ErrorText>}
              <Button
                variant="primary"
                size="full"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={
                  loading ||
                  values.brandName === "" ||
                  values.brandName === brand?.brandName
                }
              >
                Save
              </Button>
              {brand && (
                <Button
                  variant="secondary"
                  size="full"
                  type="button"
                  disabled={loading}
                  className="mt-[8px]"
                  onClick={() => onDelete(brand._id)}
                >
                  Delete
                </Button>
              )}
            </div>
          </>
        </Modal>
      )}
    </Formik>
  );
};
