import { EventsTimeline } from "../EventsTimeline/EventsTimeline";
import { Card } from "@/components/Card/Card";

export const EventsTimelineTab = () => (
  <Card
    title="Events Timeline"
    titleBackgroundColor="brand-heavy-warmth-25"
    titleColor="brand-heavy-teal-100"
  >
    <EventsTimeline />
  </Card>
);
