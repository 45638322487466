import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AdminTransaction } from "../pages/TransactionDetails/types";
import { ApiController } from "../../service/ApiController";
import { useLocation } from "react-router-dom";
import { TransactionEvents } from "../../types";

interface TransactionContext {
  transaction?: AdminTransaction;
  setTransaction?: (newData: AdminTransaction) => void;
  loading?: boolean;
  error?: string;
  transactionEvents: TransactionEvents;
  getTransactionEvents?: () => void;
}

export const TransactionContext = createContext<TransactionContext>({
  transactionEvents: [],
  loading: true,
});

export const useTransactionContext = () => {
  const context = useContext(TransactionContext);
  if (!context) {
    throw new Error(
      "useTransactionContext must be used within a TransactionProvider",
    );
  }
  return context;
};

interface TransactionProviderProps {
  children: React.ReactNode;
}

export const TransactionProvider = ({ children }: TransactionProviderProps) => {
  const location = useLocation();
  const transactionId = location.pathname.split("/")[3];

  const [transaction, setTransaction] = useState<AdminTransaction | undefined>(
    undefined,
  );
  const [transactionEvents, setTransactionEvents] = useState<TransactionEvents>(
    [],
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const getTransactionEvents = useCallback(async () => {
    try {
      const transactionEvents =
        await ApiController.fetchTransactionEvents(transactionId);
      setTransactionEvents(transactionEvents);
    } catch {
      setError("There was an error while fetching the transaction events");
    }
  }, [transactionId]);

  useEffect(() => {
    async function getTransactionDetails() {
      setError("");

      try {
        const { transaction: transactionRes } =
          await ApiController.findTransaction(transactionId);

        await getTransactionEvents();

        setTransaction(transactionRes);
      } catch {
        setError("There was an error while fetching the transaction details");
      }
    }

    setLoading(true);
    getTransactionDetails();
    setLoading(false);
  }, [getTransactionEvents, transactionId]);

  return (
    <TransactionContext.Provider
      value={{
        transaction,
        setTransaction,
        error,
        loading,
        transactionEvents,
        getTransactionEvents,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};
