import { useState } from "react";
import { Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";
import { AxiosError } from "axios";

import TextInput from "@/components/Input/TextInput";
import { Button } from "@/components/Button/Button";
import { ApiController } from "../../../service/ApiController";
import { CompanySellerState } from "@schema";
import { Spinner } from "../../../components/Spinner/Spinner";
import ErrorText from "@/foundation/Typography/ErrorText";
import { createCompanySellerValues } from "./utils";

const companyNumberForm = z.object({
  companyNumber: z.string().min(1),
});

interface FindCompanyFormProps {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  updateSellerDetails: (values: CompanySellerState) => void;
}

export const FindCompanyForm = ({
  isLoading,
  setIsLoading,
  updateSellerDetails,
}: FindCompanyFormProps) => {
  const [error, setError] = useState<string>("");

  const findCompanyByNumber = async (companyNumber: string) => {
    setIsLoading(true);
    try {
      const company = await ApiController.findCompanyByNumber(companyNumber);
      const companyValues = createCompanySellerValues(company);

      updateSellerDetails(companyValues);
      setError("");
    } catch (error) {
      if (error instanceof AxiosError && error.status === 404) {
        setError("Company not found");
      } else if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("There was an error finding company by company number");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full flex flex-col">
      <Formik
        initialValues={{
          companyNumber: "",
        }}
        onSubmit={(values) => findCompanyByNumber(values.companyNumber)}
        validate={withZodSchema(companyNumberForm)}
      >
        {({ handleSubmit, values, errors, touched, handleChange }) => {
          return (
            <>
              <div className="flex flex-col gap-[16px] md:flex-row md:flex-wrap">
                <TextInput
                  showIcon={true}
                  icon="Search"
                  className="md:w-[320px]"
                  name="companyNumber"
                  variant={error ? "error" : "enabled"}
                  label="Search by company number"
                  onChange={(newVal) => {
                    handleChange(newVal);
                    setError("");
                  }}
                  value={values.companyNumber}
                />
                <Button
                  size="2xl"
                  variant="primary"
                  disabled={!values.companyNumber}
                  type="button"
                  onClick={() => handleSubmit()}
                  className="w-full md:w-[200px] h-[64px] md:mb-[0px] mb-[16px]"
                >
                  <span id="button-text">
                    {isLoading ? (
                      <span className="flex items-center">
                        <Spinner /> Processing...
                      </span>
                    ) : (
                      <div>Find company</div>
                    )}
                  </span>
                </Button>
              </div>
              {error && <ErrorText>{error}</ErrorText>}
              {errors.companyNumber && touched.companyNumber && (
                <ErrorText>{errors.companyNumber}</ErrorText>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};
