import { FieldSet } from "../../FieldSet/FieldSet";
import { Input } from "../../../Formik/Input/Input";

interface PriceProps {
  priceTitle: string;
  priceDescription?: string;
  typeOfPrice: string;
  placeholder: string;
}

export const Price = ({
  priceTitle,
  priceDescription = "",
  typeOfPrice,
  placeholder,
}: PriceProps) => (
  <FieldSet legend={priceTitle} description={priceDescription}>
    <Input fieldName={typeOfPrice} label={placeholder} isCurrency={true} />
  </FieldSet>
);
