import * as Sentry from "@sentry/react";

import { operations } from "@shared/types.d";
import { api } from "../../../service/ApiController";
import { getSearchParamsStringFromObject } from "@utils";

type GetEstateAgentsQueryParams =
  operations["EstateAgentController_findAll"]["parameters"]["query"];

type EstateAgentsApiResponse =
  operations["EstateAgentController_findAll"]["responses"]["200"]["content"]["application/json"];

export const findAllEstateAgents = async (
  params: GetEstateAgentsQueryParams,
): Promise<EstateAgentsApiResponse> => {
  try {
    const response = await api.get(
      `/estate-agent?${getSearchParamsStringFromObject(params)}`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);

    throw new Error("There was an error fetching estate agents");
  }
};
