import { useState } from "react";

import { useEstateAgentContext } from "../../context/EstateAgentProvider";
import { EstateAgent } from "../../../types";
import { AddPersonnel } from "./AddPersonnel";
import { EstateAgentNegotiatorModal } from "../Modals/EstateAgentNegotiatorModal";

type Negotiator = NonNullable<EstateAgent["negotiators"]>[0];

export const AddNegotiator = () => {
  const { estateAgent, updateEstateAgent } = useEstateAgentContext();

  const [showNegotiatorModal, setShowNegotiatorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmitNewNegotiator = async (newNegotiator: Negotiator) => {
    const { error } = await updateEstateAgent!({
      ...estateAgent!,
      negotiators: [...(estateAgent?.negotiators || []), newNegotiator],
    });

    if (error) {
      setErrorMessage(error);
    } else {
      setShowNegotiatorModal(false);
    }
  };

  return (
    <>
      <AddPersonnel
        buttonLabel="Add negotiator"
        onButtonClick={() => setShowNegotiatorModal(true)}
      />
      {showNegotiatorModal && (
        <EstateAgentNegotiatorModal
          onClose={() => setShowNegotiatorModal(false)}
          onSubmit={onSubmitNewNegotiator}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};
