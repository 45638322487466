import { useEffect, useState } from "react";
import { format } from "date-fns";
import { AxiosError } from "axios";

import { useTransactionContext } from "../../context/TransactionProvider";
import { ThirdfortBlock } from "../TransactionBlocks/ThirdfortBlock";
import Typography from "@/foundation/Typography/Typography";
import { Card } from "@/components/Card/Card";
import { Button } from "@/components/Button/Button";
import { TransactionEvents } from "../../../types";
import { ApiController } from "../../../service/ApiController";
import ErrorText from "@/foundation/Typography/ErrorText";

export const ThirdfortSellers = () => {
  const {
    transaction,
    transactionEvents,
    getTransactionEvents,
    error: transactionError,
  } = useTransactionContext();

  const [
    estateAgentRequestsThirdfortChecks,
    setEstateAgentRequestsThirdfortChecks,
  ] = useState(false);

  const [estateAgentEmailSentEvents, setEstateAgentEmailSentEvents] =
    useState<TransactionEvents>([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (transaction) {
      setEstateAgentRequestsThirdfortChecks(
        !!transaction?.estate_agent?.thirdfort?.requestsSellerThirdfortChecks,
      );
    }
  }, [transaction]);

  useEffect(() => {
    if (transactionEvents) {
      const filteredEvents = transactionEvents.filter(
        (x) => x.event_name === "thirdfort_requester_email_sent",
      );

      setEstateAgentEmailSentEvents(filteredEvents);
    }
  }, [transactionEvents]);

  const onSendEstateAgentEmail = async (transactionId: string) => {
    try {
      setLoading(true);
      setError("");

      await ApiController.sendEstateAgentThirdfortEmail(transactionId);
      await getTransactionEvents!();
    } catch (error) {
      console.log(JSON.stringify(error));
      let errorMessage = "";

      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data?.message || error.message;
      }

      setError(errorMessage || "There was an error sending the email");
    } finally {
      setLoading(false);
    }
  };

  if (transaction) {
    return (
      <>
        {estateAgentRequestsThirdfortChecks ? (
          <Card
            title={`Sellers`}
            titleBackgroundColor="brand-heavy-warmth-25"
            titleColor="brand-heavy-teal-100"
          >
            <div className="flex flex-col items-center">
              <Typography className="mb-[16px]">
                The estate agent assigned to this transaction receives Thirdfort
                checks for sellers.
              </Typography>
              {estateAgentEmailSentEvents.length ? (
                <>
                  <Typography>
                    An email has been sent to the estate agent.
                  </Typography>
                  <Typography className="mb-[16px]">{`Email previously sent: ${estateAgentEmailSentEvents?.map((x) => format(new Date(x.date_time), "dd/MM/yyyy")).join(", ")}`}</Typography>
                </>
              ) : (
                <>
                  <Typography className="mb-[16px]">
                    Send them an email to let them know the solicitor recipient
                    details
                  </Typography>
                </>
              )}

              <Button
                variant="primary"
                onClick={() => onSendEstateAgentEmail(transaction._id)}
                disabled={loading}
              >
                {estateAgentEmailSentEvents?.length
                  ? "Send email again"
                  : "Send email"}
              </Button>
              {error && <ErrorText>{error || transactionError}</ErrorText>}
            </div>
          </Card>
        ) : (
          transaction.sellers.map((seller, index) => (
            <ThirdfortBlock
              key={`thirdfort-seller-${index}`}
              title={`Seller ${index + 1}`}
              address={transaction.property_id.address}
              profile={seller.user}
              solicitorEmail={
                transaction.lawyer_group?.seller.solicitor.email.toLowerCase() ||
                ""
              }
              leftLabel={`Seller ${index + 1} of ${transaction.sellers.length}`}
            />
          ))
        )}
      </>
    );
  }

  return null;
};
