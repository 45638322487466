import * as Sentry from "@sentry/react";

import { api } from ".";
import { PurchaseIntent } from "../../admin/pages/TransactionDetails/types";

export const findPurchaseIntent = async (
  purchaseIntent_id: string,
): Promise<PurchaseIntent> => {
  try {
    const response = await api.get(
      `/purchase-intent/get-purchase-intent/${purchaseIntent_id}`,
    );
    return response.data.purchaseIntent;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("There was an error finding purchase intent");
  }
};
