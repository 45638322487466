import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

export type CreateEstateAgentResponse =
  paths["/api/estate-agent"]["post"]["responses"]["201"]["content"]["application/json"];

export type CreateEstateAgentRequest =
  paths["/api/estate-agent"]["post"]["requestBody"]["content"]["application/json"];

export const createEstateAgent = async (
  newEstateAgent: CreateEstateAgentRequest,
): Promise<CreateEstateAgentResponse> => {
  try {
    const response = await api.post(`/estate-agent`, newEstateAgent);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Error creating estate agent");
  }
};
