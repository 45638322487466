export const getSearchParamsStringFromObject = (
  params: { [key: string]: unknown } | undefined,
): string => {
  if (!params) {
    return "";
  }

  const searchParams = new URLSearchParams();

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        searchParams.set(key, value.toString());
      }
    });
  }

  return searchParams.toString();
};
