import * as Sentry from "@sentry/react";

import {
  api,
  transformAdminTransactionForApi,
} from "../../../service/ApiController";
import {
  AdminTransaction,
  AdminTransactionRO,
} from "../../pages/TransactionDetails/types";

export const updateAdminTransaction = async (
  transaction: AdminTransaction,
): Promise<AdminTransactionRO> => {
  try {
    const transformedData = transformAdminTransactionForApi(transaction);
    const response = await api.put(
      `/transaction/${transformedData._id}`,
      transformedData,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Update transaction failed");
  }
};
