import * as Sentry from "@sentry/react";
import { api } from ".";

export const verifyUser = async (token: string | null) => {
  try {
    const response = await api.get("/authentication/verify-user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Authentication request failed");
  }
};
