import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

export type CreateTransactionsGroupResponse =
  paths["/api/transactions-group"]["post"]["responses"]["201"]["content"]["application/json"];

export type CreateTransactionsGroupRequest =
  paths["/api/transactions-group"]["post"]["requestBody"]["content"]["application/json"];

export const createTransactionsGroup = async (
  newTransactionsGroup: CreateTransactionsGroupRequest,
): Promise<CreateTransactionsGroupResponse> => {
  try {
    const response = await api.post(
      `/transactions-group`,
      newTransactionsGroup,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Error creating transactions group");
  }
};
