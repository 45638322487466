import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

type FindAllUsers = paths["/api/users"]["get"];

type FindAllUsersQuery = FindAllUsers["parameters"]["query"];
type FindAllUsersResponse =
  FindAllUsers["responses"]["200"]["content"]["application/json"];

export const findAllUsers = async (
  params: FindAllUsersQuery,
): Promise<FindAllUsersResponse> => {
  const queryParams: { [key: string]: string } = {};

  if (params?.isActive !== undefined) {
    queryParams["isActive"] = params.isActive.toString();
  }

  if (params?.type !== undefined) {
    queryParams["type"] = params.type.toString();
  }

  const searchParams = new URLSearchParams(queryParams);

  try {
    const response = await api.get(`/users?${searchParams.toString()}`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Users not found");
  }
};
