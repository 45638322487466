export const items = {
  firstLine: [
    {
      id: 1,
      icon: "Lightning",
      subtitle: "Sell 80% faster than the market",
      description: "",
    },
    {
      id: 2,
      icon: "Stress",
      subtitle: "Sell without fear of fall throughs",
      description: "",
    },
  ],
  secondLine: [
    {
      id: 3,
      subtitle: "Propelr's a no brainer",
      description:
        "From the moment we instructed Propelr to exchange of contracts we couldn't believe how seamless the process was.",
    },
    {
      id: 4,
      subtitle: "A brilliant service",
      description:
        "Having all of the survey information, searches and title information pre-prepared made exchanging contracts extremely quick and efficient. Two weeks from start to finish.",
    },
  ],
};
