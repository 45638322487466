import { useEffect, useState } from "react";

import ErrorText from "@/foundation/Typography/ErrorText";
import { Table } from "@/components/Table/Table";
import { Button } from "@/components/Button/Button";

import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { EstateAgentBrandModal } from "../../components/Modals/EstateAgentBrandModal";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { ApiController } from "../../../service/ApiController";
import { EstateAgentBrand } from "../../../types";

export const EstateAgentBrands = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [brands, setBrands] = useState<EstateAgentBrand[]>([]);
  const [brandBeingEditted, setBrandBeingEditted] =
    useState<EstateAgentBrand>();

  const getBrands = async () => {
    setLoading(true);
    try {
      const brandsRes = await ApiController.findAllEstateAgentBrands();
      setBrands(brandsRes);
    } catch {
      setError("There was an error fetching the brands");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBrands();
  }, []);

  const onCloseModal = () => {
    setModalOpen(false);
    setBrandBeingEditted(undefined);
  };

  const onOpenModal = (brand?: EstateAgentBrand) => {
    setModalOpen(true);
    setBrandBeingEditted(brand);
  };

  const columns = [{ key: "brandName", title: "Brand Name" }];

  return (
    <>
      <div className="flex">
        <AdminTitle title="Estate Agent Brands" />
        <Button
          className="mt-[20px] md:mt-[40px]"
          variant="primary"
          onClick={() => onOpenModal()}
        >
          Add brand
        </Button>
      </div>
      {loading && <FullPageLoader />}
      {error && <ErrorText>{error}</ErrorText>}

      <Table
        columns={columns}
        rows={brands.map((x) => ({
          data: { ...x },
          onClick: () => onOpenModal(x),
        }))}
      />
      {modalOpen && (
        <EstateAgentBrandModal
          brand={brandBeingEditted}
          onClose={onCloseModal}
          onSaved={async () => {
            await getBrands();
            setModalOpen(false);
          }}
        />
      )}
    </>
  );
};
