import { ComponentProps, useEffect, useState } from "react";

import Typography from "../../foundation/Typography/Typography";
import Icons, { TextInputIconProps } from "../../foundation/Icons/Icons";

export type SelectableCardProps = ComponentProps<"div"> & {
  variant?: "enabled" | "disabled" | "error" | "success";
  label: string;
  message: string;
  radioName: string;
  icon: TextInputIconProps;
  whiteIcon: TextInputIconProps;
  value: string;
  option: string;
  handleChange: (option: string) => void;
  isInitialSelected?: string;
};

const SelectableCard = ({
  variant = "enabled",
  label,
  message,
  radioName,
  icon,
  whiteIcon,
  value,
  option,
  handleChange,
  isInitialSelected,
  ...props
}: SelectableCardProps) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleSelection = () => {
    if (!isSelected) {
      setIsSelected(true);
      if (variant !== "disabled") {
        handleChange(option);
      }
    }
  };

  useEffect(() => {
    if (value !== option && isSelected) {
      setIsSelected(false);
    }
  }, [value, option, isSelected]);

  return (
    <label htmlFor={option}>
      <div
        {...props}
        className={`flex justify-start items-center min-h-[95px] p-[16px] rounded-2xl hover:border-ui-black-25 ${
          variant === "disabled" ? "cursor-not-allowed" : "cursor-pointer"
        }
        ${props.className}`}
        onClick={handleSelection}
      >
        <input
          type="radio"
          name={radioName}
          disabled={variant === "disabled"}
          className="hidden"
          value={value}
          onChange={() => handleChange(option)}
        />

        <div className="flex justify-center shrink-0 w-[50px] mr-[16px]">
          <Icons
            iconName={option === isInitialSelected ? whiteIcon : icon}
            size="50"
          />
        </div>

        <div>
          <Typography
            type="p"
            variant="base"
            weight="bold"
            className={`${
              option === value
                ? "text-ui-white-100"
                : "text-brand-heavy-teal-100"
            }`}
          >
            {label}
          </Typography>

          <Typography
            type="p"
            variant="sm"
            weight="regular"
            className={
              option === value
                ? "text-ui-white-100"
                : "text-brand-heavy-teal-75"
            }
          >
            {message}
          </Typography>
        </div>
      </div>
    </label>
  );
};
export default SelectableCard;
