import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { withZodSchema } from "formik-validator-zod";
import { Field, FieldProps, Form, Formik } from "formik";

import Typography from "@/foundation/Typography/Typography";
import ErrorText from "@/foundation/Typography/ErrorText";
import { Select } from "@/components/Input/Select";
import { Modal } from "../../../components/Modal/Modal";
import { createBlob, generateCsvString } from "./utils/csvUtils";
import { AdminTransaction } from "../../pages/TransactionDetails/types";
import { generateYearMonthOptions, getAndSortDates } from "./utils/dateUtils";
import {
  getTransactionsWithLawyerGroupAssignedOnMonth,
  organiseRecordsByLawyerGroup,
} from "./utils/lawyerGroupUtils";

interface InvoicingBlockProps {
  onCloseModal: () => void;
  transactions: AdminTransaction[];
  invoicingAmount: string | undefined;
}

const InvoicingForm = z.object({
  invoicing_period: z.string().min(1, { message: "Period is required" }),
});

export const InvoicingBlock = ({
  onCloseModal,
  transactions,
  invoicingAmount,
}: InvoicingBlockProps) => {
  const [transactionsWithLawyerGroup, setTransactionsWithLawyerGroup] =
    useState<AdminTransaction[]>([]);
  const [error, setError] = useState<string | null>(null);

  const checkNoInvoicingAmountError = useCallback((): boolean => {
    if (!invoicingAmount) {
      setError(
        "There is no invoicing amount configured. Please set it before continuing.",
      );
      return true;
    }
    setError(null);
    return false;
  }, [invoicingAmount]);

  useEffect(() => {
    checkNoInvoicingAmountError();
  }, [checkNoInvoicingAmountError]);

  useEffect(() => {
    const filteredTransactions = transactions.filter(
      (transaction) =>
        transaction.lawyer_group &&
        transaction.date_solicitor_groups_assigned?.assigned_at,
    );

    setTransactionsWithLawyerGroup(filteredTransactions);
  }, [transactions]);

  const handleDownloadCsv = (selectedPeriod: string): void => {
    if (checkNoInvoicingAmountError()) return;

    const parsedPeriod = JSON.parse(selectedPeriod);

    const transactionsByMonth = getTransactionsWithLawyerGroupAssignedOnMonth(
      parsedPeriod,
      transactionsWithLawyerGroup,
    );

    const groupedRecords = organiseRecordsByLawyerGroup(
      transactionsByMonth,
      invoicingAmount as string,
    );

    const csvString = generateCsvString(groupedRecords, parsedPeriod);

    createBlob(csvString, parsedPeriod, (error) => {
      setError(`Failed to create or download the CSV file: ${error.message}`);
    });
  };

  const getInvoicingPeriodOptions = (
    transactions: AdminTransaction[],
  ): {
    label: string;
    value: string | { year: string; month: string };
    disabled?: boolean;
  }[] => {
    const dates = getAndSortDates(transactions);
    const yearMonthOptions = generateYearMonthOptions(dates);
    return [
      {
        label: "Select period",
        value: "",
        disabled: true,
      },
      ...yearMonthOptions,
    ];
  };

  return (
    <Formik
      initialValues={{
        invoicing_period: "",
      }}
      validate={withZodSchema(InvoicingForm)}
      onSubmit={(values) => handleDownloadCsv(values.invoicing_period)}
    >
      {({ handleSubmit, errors, values, touched }) => {
        return (
          <Form>
            <Modal
              title="Invoicing"
              cancelButtonFunction={() => onCloseModal()}
              saveButtonFunction={() => {
                handleSubmit();
                if (!error) {
                  onCloseModal();
                }
              }}
              saveButtonText="Download CSV"
              isSaveButtonDisabled={!values.invoicing_period}
              hideSaveButton={!invoicingAmount}
            >
              <>
                {invoicingAmount ? (
                  <>
                    <Typography
                      type="h3"
                      variant="h3"
                      weight="bold"
                      className="text-brand-heavy-teal-100 mt-[40px] mb-[16px]"
                    >
                      Export CSV
                    </Typography>
                    <div
                      className="flex flex-col w-[60%]"
                      data-testid="invoicing"
                    >
                      <div className="flex flex-col gap-[16px]">
                        <Field name="invoicing_period">
                          {({ field }: FieldProps) => {
                            return (
                              <Select
                                {...field}
                                name="invoicing_period"
                                id="invoicing_period"
                                data-testid="invoicing_period"
                                options={getInvoicingPeriodOptions(
                                  transactionsWithLawyerGroup,
                                )}
                                variant={
                                  errors.invoicing_period &&
                                  touched.invoicing_period
                                    ? "error"
                                    : !invoicingAmount
                                      ? "disabled"
                                      : "enabled"
                                }
                              />
                            );
                          }}
                        </Field>
                        {errors.invoicing_period &&
                          touched.invoicing_period && (
                            <ErrorText>{errors.invoicing_period}</ErrorText>
                          )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>{error && <ErrorText>{error}</ErrorText>}</>
                )}
              </>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
