import { useEffect, useState } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";

import { Button } from "@/components/Button/Button";
import Typography from "@/foundation/Typography/Typography";
import { Input } from "../../components/Formik/Input/Input";
import { ApiController } from "../../service/ApiController";

type ReferralCodeApplyFormProps = {
  onReferralCodeVerified: React.Dispatch<React.SetStateAction<string>>;
};

type ReferralCodeEffectProps = {
  isReferralCodeValid: boolean;
  setIsReferalCodeValid: React.Dispatch<React.SetStateAction<boolean>>;
} & ReferralCodeApplyFormProps;

const ReferralCodeEffect = ({
  isReferralCodeValid,
  onReferralCodeVerified,
  setIsReferalCodeValid,
}: ReferralCodeEffectProps) => {
  const { values } = useFormikContext<{ referral_code: string }>();

  useEffect(() => {
    if (values.referral_code === "" && isReferralCodeValid) {
      onReferralCodeVerified("");
      setIsReferalCodeValid(false);
    }
  }, [
    values.referral_code,
    isReferralCodeValid,
    setIsReferalCodeValid,
    onReferralCodeVerified,
  ]);

  return null;
};

export const ReferralCodeApplyForm = ({
  onReferralCodeVerified,
}: ReferralCodeApplyFormProps) => {
  const [isReferralCodeValid, setIsReferralCodeValid] = useState(false);

  const onApplyReferralCode = async (
    code: string,
    setFieldError: (field: string, message: string) => void,
  ) => {
    try {
      const isValid = await ApiController.validateReferralCode(code);
      setIsReferralCodeValid(isValid);

      if (isValid) {
        setFieldError("referral_code", "");
        onReferralCodeVerified(code);
      } else {
        setFieldError("referral_code", "Code not valid");
        onReferralCodeVerified("");
      }
    } catch (error) {
      console.error("Error validating code", error);
      setFieldError("referral_code", "There was an error validating the code");
    }
  };

  const ReferralCodeForm = z.object({
    referral_code: z.string(),
  });

  return (
    <Formik
      initialValues={{
        referral_code: "",
      }}
      validate={withZodSchema(ReferralCodeForm)}
      onSubmit={(values, { setFieldError }) =>
        onApplyReferralCode(values.referral_code, setFieldError)
      }
    >
      {({ handleSubmit, errors, values }) => {
        return (
          <Form>
            <ReferralCodeEffect
              isReferralCodeValid={isReferralCodeValid}
              onReferralCodeVerified={onReferralCodeVerified}
              setIsReferalCodeValid={setIsReferralCodeValid}
            />
            <div className="my-[22px] text-ui-black-75">
              <Typography type="span" variant="sm" weight="regular">
                Have you been given a code? Enter it here.
              </Typography>
              <div className="my-[22px] flex md:flex-row flex-col justify-between mb-[18px] md:w-auto w-full">
                <div className="flex flex-col">
                  <Input
                    fieldName="referral_code"
                    label="Enter code"
                    success={isReferralCodeValid}
                  />
                </div>
                <Button
                  variant="secondary"
                  size="2xl"
                  type="submit"
                  className="md:ml-[12px]"
                  onClick={() => handleSubmit()}
                  disabled={
                    !values["referral_code"] ||
                    !!errors.referral_code ||
                    isReferralCodeValid
                  }
                >
                  Apply
                </Button>
              </div>
            </div>

            <hr className="my-[22px] border-t-2 border-brand-heavy-warmth-100" />
          </Form>
        );
      }}
    </Formik>
  );
};
