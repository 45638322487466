import { ReactElement } from "react";
import Typography from "../../foundation/Typography/Typography";

export interface CardProps {
  title?: string;
  titleColor?: string;
  titleBackgroundColor?: string;
  titleRightContent?: ReactElement;
  backgroundColour?: string;
  children?: ReactElement;
}

export const Card = ({
  title,
  children,
  titleColor = "ui-white-100",
  titleBackgroundColor = "brand-heavy-teal-100",
  titleRightContent,
  backgroundColour,
}: CardProps) => {
  return (
    <div
      className={`${backgroundColour ? backgroundColour : "bg-ui-white-100"} shadow-questionType rounded-xl border border-ui-black-10`}
    >
      {title && (
        <div
          className={`h-[64px] bg-${titleBackgroundColor} flex items-center justify-between rounded-t-lg px-[32px]`}
        >
          <Typography
            type="h4"
            variant="h4"
            weight="bold"
            className={`text-${titleColor}`}
          >
            {title}
          </Typography>
          {titleRightContent && <div>{titleRightContent}</div>}
        </div>
      )}
      <div className="p-[24px] pl-[32px] pr-[32px]">{children}</div>
    </div>
  );
};
