import Typography from "@/foundation/Typography/Typography";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { AdminTitle } from "../AdminPageTemplate/AdminTitle";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import { TransactionsGroupBlock } from "./TransactionsGroupBlock";

interface TransactionsGroupProps {
  title?: string;
}

export const TransactionsGroup = ({
  title = "Transaction Group",
}: TransactionsGroupProps) => {
  const { loading, error } = useTransactionsGroupContext();

  return (
    <>
      <AdminTitle title={title} />
      {loading && <FullPageLoader />}
      {error && (
        <Typography variant="lg" weight="bold" type="p">
          {error}
        </Typography>
      )}

      <TransactionsGroupBlock />
    </>
  );
};
