import * as Sentry from "@sentry/react";

import { TransactionResponse } from "@shared/types";
import { api } from ".";

export const findTransaction = async (
  _id: string,
): Promise<{ transaction: TransactionResponse }> => {
  try {
    const response = await api.get(`/transaction/${_id}`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("There was an error finding transaction");
  }
};
