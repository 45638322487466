import { useState } from "react";
import { Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";

import { Button } from "@/components/Button/Button";
import { CompanySellerState } from "@schema";
import Typography from "@/foundation/Typography/Typography";
import { CompanySellerForm } from "../TransactionsGroupDetailsBlocks/SellerDetailsForm";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import { initialCompanySellerStateObj } from "./initialDetails";
import { companySellerSchema } from "../../../schema/TransactionsGroupSchema";
import { FindCompanyForm } from "./FindCompanyForm";

interface AddSellerDetailsProps {
  setDisplaySellerDetailsForm: (value: boolean) => void;
  displaySellerDetailsForm: boolean;
}

export const AddSellerDetails = ({
  setDisplaySellerDetailsForm,
  displaySellerDetailsForm,
}: AddSellerDetailsProps) => {
  const { companySeller, setCompanySeller } = useTransactionsGroupContext();

  const [isLoading, setIsLoading] = useState(false);

  const updateSellerDetails = (values: CompanySellerState) => {
    setCompanySeller!(values);
    setDisplaySellerDetailsForm(false);
  };

  return (
    <div className="w-full flex flex-col">
      <Formik
        initialValues={companySeller || initialCompanySellerStateObj}
        onSubmit={(values) => updateSellerDetails({ ...values })}
        validate={withZodSchema(companySellerSchema)}
      >
        {(formik) => {
          return (
            <>
              <FindCompanyForm
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                updateSellerDetails={updateSellerDetails}
              />

              {displaySellerDetailsForm ? (
                <CompanySellerForm />
              ) : (
                <button
                  className="underline cursor-pointer text-brand-heavy-teal-75 my-[16px] self-start"
                  onClick={() => setDisplaySellerDetailsForm(true)}
                  type="button"
                >
                  <Typography
                    variant="base"
                    weight="regular"
                    type="span"
                    className="text-brand-heavy-teal-75"
                  >
                    Add a new company
                  </Typography>
                </button>
              )}

              {displaySellerDetailsForm && (
                <Button
                  size="2xl"
                  variant="primary"
                  type="button"
                  onClick={() => formik.handleSubmit()}
                  className="w-full md:w-[200px] h-[64px] my-[16px]"
                >
                  Save company
                </Button>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};
