import { useEffect } from "react";

import { Testimonial } from "../../components/Testimonial/Testimonial";
import Icons, { TextInputIconProps } from "@/foundation/Icons/Icons";
import Typography from "@/foundation/Typography/Typography";
import Logo from "../../assets/logo-horizontal.svg";
import { items } from "./items";

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create: (options: {
          region: string;
          portalId: string;
          formId: string;
          target: string;
        }) => void;
      };
    };
  }
}

const loadHubspotFormScript = (onloadCallback: () => void) => {
  const script = document.createElement("script");
  script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
  script.async = true;
  script.onload = onloadCallback;
  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
};

const PageTitle = () => (
  <>
    <span className="text-brand-warmth-100">Fast</span> and{" "}
    <span className="text-brand-warmth-100">confident</span> sales
  </>
);

export const PartnerSignUp = () => {
  useEffect(() => {
    const handleScriptLoad = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "144481014",
          formId: "9d9929ba-c525-444e-8fb3-5701a01564a3",
          target: "#hubspotPartnerSignUpForm",
        });
      }
    };

    const removeScript = loadHubspotFormScript(handleScriptLoad);

    return () => removeScript();
  }, []);

  return (
    <section className="bg-brand-heavy-teal-100 bg-[url('/src/assets/house-texture-clipped.svg')] bg-no-repeat bg-left-bottom">
      <div className="flex flex-col lg:flex-row md:justify-center items-center p-[12px] md:p-[24px] lg:min-h-screen">
        <div
          className="w-full flex flex-col lg:flex-row justify-center items-center"
          data-testid="sign-in-description"
        >
          {/* Mobile Header */}
          <header className="lg:hidden">
            <div className="flex flex-col items-start w-full max-w-[498px]">
              <Typography
                variant="h1"
                type="h1"
                weight="semi-bold"
                className="font-display text-ui-white-100 mt-[100px] mb-[32px] text-center lg:text-left"
              >
                <PageTitle />
              </Typography>
            </div>
          </header>

          {/* Form Container */}
          <div
            className="relative overflow-hidden bg-brand-medium-sand-50 rounded-2xl w-full max-w-[657px] flex flex-col justify-center items-center shrink-0 mb-[50px] lg:mr-[100px] py-[48px] px-[16px] md:px-[48px]"
            data-testid="sign-in-container"
          >
            <img src={Logo} className="mb-[36px]" alt="Company Logo" />
            <Typography
              className="font-display text-brand-heavy-teal-100 text-center mb-[36px]"
              type="h2"
              variant="h2"
              weight="semi-bold"
              data-testid="signup-title"
            >
              Sign-up as a partner
            </Typography>

            <div className="relative w-full">
              <div
                className="w-full px-[24px] md:px-[48px] min-h-[100px]"
                id="hubspotPartnerSignUpForm"
              ></div>
              <div className="absolute bottom-[0] w-full h-[70px] bg-brand-medium-sand-50"></div>
            </div>
          </div>

          {/* Details Section */}
          <section className="flex flex-col md:flex-wrap md:flex-row justify-between max-w-[498px] px-[24px] md:px-0">
            {/* Desktop Header */}
            <header className="hidden lg:block">
              <div className="flex flex-col items-start w-full max-w-[498px]">
                <Typography
                  variant="h1"
                  type="h1"
                  weight="semi-bold"
                  className="font-display text-ui-white-100 mb-[32px] text-center lg:text-left"
                >
                  <PageTitle />
                </Typography>
              </div>
            </header>

            {/* First Line of Items */}
            <div className="flex flex-row gap-[16px]">
              {items.firstLine.map((item) => (
                <div
                  key={item.id}
                  className="md:w-[222px] mb-[36px]"
                  data-testid="item-description"
                >
                  <Icons
                    iconName={item.icon as TextInputIconProps}
                    colour="electric"
                    size="36"
                  />
                  <Typography
                    type="h3"
                    variant="h3"
                    weight="bold"
                    className="font-display text-ui-white-100 text-left my-[12px]"
                  >
                    {item.subtitle}
                  </Typography>
                </div>
              ))}
            </div>

            <div className="w-full">
              <hr className="mb-[32px] border-t border-brand-heavy-teal-50" />
            </div>

            {/* Second Line of Testimonials */}
            {items.secondLine.map((item) => (
              <article key={item.id}>
                <Testimonial
                  role="sell"
                  subtitle={item.subtitle}
                  description={item.description}
                />
              </article>
            ))}
          </section>
        </div>
      </div>
    </section>
  );
};
