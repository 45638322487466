import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

type EstateAgentBrandBody =
  paths["/api/estate-agent-brand/{id}"]["put"]["requestBody"]["content"]["application/json"];

type EstateAgentBrandApiResponse =
  paths["/api/estate-agent-brand/{id}"]["put"]["responses"]["200"]["content"]["application/json"];

export const updateEstateAgentBrand = async (
  id: string,
  body: EstateAgentBrandBody,
): Promise<EstateAgentBrandApiResponse> => {
  try {
    const response = await api.put(`/estate-agent-brand/${id}`, body);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data?.message);
    }
    throw error;
  }
};
