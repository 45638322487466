import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { Table, TableProps } from "../Table/Table";
import { AddSellerDetails } from "./AddSellerDetails";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";

export const SellerDetailsBlock = () => {
  const { companySeller } = useTransactionsGroupContext();

  const [fields, setFields] = useState<TableProps[]>([]);
  const [displaySellerDetailsForm, setDisplaySellerDetailsForm] =
    useState(false);

  useEffect(() => {
    if (companySeller) {
      const updatedFields = [
        { label: "Name", value: companySeller.name },
        { label: "Company Number", value: companySeller.companyNumber || "" },
        {
          label: "Address Line 1",
          value: companySeller.address?.line_1 || "",
        },
        {
          label: "Address Line 2",
          value: companySeller.address?.line_2 || "",
        },
        {
          label: "Address Line 3",
          value: companySeller.address?.line_3 || "",
        },
        {
          label: "Post Town",
          value: companySeller.address?.post_town || "",
        },
        {
          label: "Country",
          value: companySeller.address?.country || "",
        },
        {
          label: "Postcode",
          value: companySeller.address?.postcode || "",
        },
      ];
      setFields(updatedFields);
    }
  }, [companySeller]);

  return (
    <Card title="Seller Details" backgroundColour="bg-brand-heavy-warmth-25">
      {displaySellerDetailsForm || !companySeller?.name ? (
        <AddSellerDetails
          setDisplaySellerDetailsForm={setDisplaySellerDetailsForm}
          displaySellerDetailsForm={displaySellerDetailsForm}
        />
      ) : (
        <div className="w-full">
          <Card
            titleColor="brand-heavy-teal-100"
            titleBackgroundColor="brand-heavy-warmth-50"
            title="Company Details"
            titleRightContent={
              <button onClick={() => setDisplaySellerDetailsForm(true)}>
                Edit
              </button>
            }
          >
            <div>
              {fields.map(({ value, label, includeCopyButton }) => (
                <div key={label} className="flex jusify-top">
                  <Table
                    key={value as string}
                    label={label}
                    value={value}
                    includeCopyButton={includeCopyButton}
                  />
                </div>
              ))}
            </div>
          </Card>
        </div>
      )}
    </Card>
  );
};
