import * as Sentry from "@sentry/react";

import { api, transformPurchaseIntentForApi } from ".";
import { PurchaseIntentFormState } from "../../store/PurchaseIntentForm/purchaseIntentForm";

export const createPurchaseIntent = async (
  purchaseIntent: PurchaseIntentFormState,
) => {
  try {
    const response = await api.post(
      "/purchase-intent",
      transformPurchaseIntentForApi(purchaseIntent),
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Create purchase intent failed");
  }
};
