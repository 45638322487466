import { AddressInputs } from "../../../components/Form/DetailsForm/AddressLookup/AddressInputs";
import { BasicDetailsForm } from "../../../components/Form/DetailsForm/BasicDetailsForm/BasicDetailsForm";

export const RepresentativeForm = () => (
  <>
    <BasicDetailsForm isAdditionalSeller={true} />
    <AddressInputs
      namespace="correspondenceAddress"
      addressTitle="Correspondence address"
      cardAddressTitle="Correspondence address"
    />
  </>
);
