import { FieldSet } from "../../../components/Form/FieldSet/FieldSet";
import { Input } from "../../../components/Formik/Input/Input";
import { AddressInputs } from "../../../components/Form/DetailsForm/AddressLookup/AddressInputs";

export const CompanySellerForm = () => (
  <>
    <FieldSet legend="Company name">
      <div className="flex flex-col md:flex-row md:flex-wrap gap-[16px] w-[100%]">
        <div className="w-[100%] md:max-w-[320px]">
          <Input fieldName="name" label="Name" />
        </div>
      </div>
    </FieldSet>
    <FieldSet legend="Company Number">
      <div className="flex flex-col md:flex-row md:flex-wrap gap-[16px] w-[100%]">
        <div className="w-[100%] md:max-w-[320px]">
          <Input fieldName="companyNumber" label="Company Number" />
        </div>
      </div>
    </FieldSet>
    <AddressInputs
      namespace="address"
      addressTitle="Company address"
      cardAddressTitle="Company address"
    />
  </>
);
