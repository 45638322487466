import { useNavigate } from "react-router-dom";

import { Footer } from "../../../components/Footer/Footer";
import { RepresentativeDetailsBlock } from "./RepresentativeDetailsBlock";
import { SellerDetailsBlock } from "./SellerDetailsBlock";
import { PropertyDetailsBlock } from "./PropertyDetailsBlock";
import { PaymentDetailsBlock } from "./PaymentDetailsBlock";

export const TransactionsGroupBlock = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col space-y-5 pb-[24px]">
        <RepresentativeDetailsBlock />
        <PropertyDetailsBlock />
        <SellerDetailsBlock />
        <PaymentDetailsBlock />
      </div>
      <Footer
        continueTitle="Save and Continue"
        handleContinue={() => navigate("admin/transactions-groups")}
      />
    </>
  );
};
