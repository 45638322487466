import { useState } from "react";

import { EstateAgent } from "../../../types";
import { EstateAgentNegotiatorModal } from "../Modals/EstateAgentNegotiatorModal";
import { useEstateAgentContext } from "../../context/EstateAgentProvider";
import { PersonnelDetails } from "./PersonnelDetails";

type Negotiator = NonNullable<EstateAgent["negotiators"]>[0];

export interface EditNegotiatorProps {
  negotiator: Negotiator;
  negotiators: Negotiator[];
  index: number;
}

export const EditNegotiator = ({
  negotiator,
  negotiators,
  index,
}: EditNegotiatorProps) => {
  const { updateEstateAgent, estateAgent } = useEstateAgentContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onUpdateNegotiator = async (updatedNegotiator: Negotiator) => {
    setErrorMessage("");

    const { error } = await updateEstateAgent!({
      ...estateAgent!,
      negotiators:
        negotiators.map((n, i) => (i === index ? updatedNegotiator : n)) || [],
    });

    if (error) {
      setErrorMessage(error);
    } else {
      setIsModalOpen(false);
    }
  };

  const onDeleteNegotiator = async () => {
    setErrorMessage("");

    const newNegotiators = negotiators.filter((_, i) => i !== index);

    const { error } = await updateEstateAgent!({
      ...estateAgent!,
      negotiators: newNegotiators,
    });

    if (error) {
      setErrorMessage(error);
    } else {
      setIsModalOpen(false);
    }
  };

  return (
    <div
      className="min-w-[300px] min-h-max flex-grow w-full md:w-[calc(33.33%-1.25rem)]"
      key={`valuer-card-${index}`}
    >
      <PersonnelDetails
        onEdit={() => {
          setErrorMessage("");
          setIsModalOpen(true);
        }}
        title="Negotiator"
        personnelDetails={negotiator}
        keyPrefix={`valuer-${index}`}
      />
      {isModalOpen && (
        <EstateAgentNegotiatorModal
          initialValues={negotiator}
          errorMessage={errorMessage}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(updatedNegotiator) => {
            onUpdateNegotiator(updatedNegotiator);
          }}
          showDeleteButton
          onDelete={onDeleteNegotiator}
        />
      )}
    </div>
  );
};
