import { useMemo, useRef, memo } from "react";
import { Formik, Field } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";

import ErrorText from "@/foundation/Typography/ErrorText";
import { Modal } from "../../../components/Modal/Modal";
import { Input } from "../../../components/Formik/Input/Input";
import { useEstateAgentContext } from "../../context/EstateAgentProvider";
import { Button } from "@/components/Button/Button";

export const valuerForm = z.object({
  first_name: z.string().min(1),
  last_name: z.string().optional(),
  email: z.string().email().optional(),
  phone_number: z.string().optional(),
  primary_valuer: z.boolean().optional(),
});

interface EstateAgentAddValuerModalProps {
  initialValues?: z.infer<typeof valuerForm>;
  errorMessage?: string;
  disablePrimaryValuer?: boolean;
  showDeleteButton?: boolean;
  disableDeleteButton?: boolean;
  onSubmit: (values: z.infer<typeof valuerForm>) => void;
  onClose: () => void;
  onDelete?: () => void;
}

const blankInitialValues = {
  first_name: "",
  primary_valuer: false,
};

const EstateAgentValuerModalComponent = ({
  onSubmit,
  onClose,
  onDelete,
  errorMessage,
  showDeleteButton = false,
  disableDeleteButton = false,
  initialValues = blankInitialValues,
  disablePrimaryValuer = false,
}: EstateAgentAddValuerModalProps) => {
  const { loading } = useEstateAgentContext();

  const initialValuesRef = useRef(initialValues);

  const renderError = useMemo(
    () => <ErrorText>{errorMessage}</ErrorText>,
    [errorMessage],
  );

  return (
    <Formik
      initialValues={initialValuesRef.current}
      validate={withZodSchema(valuerForm)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Modal title="Valuer" cancelButtonFunction={onClose} hideSaveButton>
          <div className="flex flex-col space-between">
            <div>
              <div className="mb-[8px]">
                <Input fieldName="first_name" label="First Name" />
              </div>
              <div className="mb-[8px]">
                <Input fieldName="last_name" label="Last Name (optional)" />
              </div>
              <div className="mb-[8px]">
                <Input fieldName="email" label="Email (optional)" />
              </div>
              <div className="mb-[8px]">
                <Input
                  fieldName="phone_number"
                  label="Phone Number (optional)"
                />
              </div>
              <div className="mb-[8px]">
                <label
                  className={`flex items-center ${
                    disablePrimaryValuer ? "text-ui-black-75/60" : "text-black"
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="primary_valuer"
                    checked={values.primary_valuer}
                    onChange={() =>
                      setFieldValue("primary_valuer", !values.primary_valuer)
                    }
                    disabled={disablePrimaryValuer}
                  />
                  <span className="ml-2">Primary valuer</span>
                </label>
              </div>
            </div>
            <div className="mt-[32px]">
              {renderError}
              <Button
                variant="primary"
                size="full"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={loading}
              >
                Save
              </Button>
              {showDeleteButton && (
                <Button
                  variant="secondary"
                  size="full"
                  type="button"
                  disabled={disableDeleteButton || loading}
                  className="mt-[8px]"
                  onClick={onDelete}
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export const EstateAgentValuerModal = memo(EstateAgentValuerModalComponent);
