import { UpdateUserResponse } from "../../service/ApiController/updateUser";
import { TransactionFormState } from "../../store/TransactionForm/transactionForm";
import { components } from "@shared/types.d";

type TransactionUpdateInput =
  components["schemas"]["TransactionUpdateRequestBodyDto"];

export const transformUpdateTransactionFormForTransactionUpdateApi = (
  transaction: TransactionFormState,
  updatedPrimarySeller: UpdateUserResponse,
): TransactionUpdateInput => {
  const { primarySeller, property } = transaction;

  if (
    !primarySeller.customerType ||
    property.onwardPurchase === null ||
    property.onwardPurchase === undefined ||
    property.hasMortgage === null ||
    property.hasMortgage === undefined ||
    !property.tenureOption ||
    !property._id
  ) {
    throw new Error("Something wrong with the validation");
  }

  const transformedPrimarySeller = {
    user: {
      _id: updatedPrimarySeller._id,
      email: updatedPrimarySeller.email,
      first_name: updatedPrimarySeller.first_name || primarySeller.firstName,
      last_name: updatedPrimarySeller.last_name || primarySeller.lastName,
      middle_name: updatedPrimarySeller.middle_name,
      phone_number:
        updatedPrimarySeller.profile?.phone_number ||
        primarySeller.mobileNumber,
      correspondence_address:
        updatedPrimarySeller.profile?.correspondence_address ||
        primarySeller.correspondenceAddress,
    },
    customer_type: primarySeller.customerType,
  };

  const transformedAdditionalSellers = property.additionalSellers.map(
    (seller) => {
      return {
        email: seller.email,
        first_name: seller.firstName,
        last_name: seller.lastName,
        middle_name: seller.middleName,
        phone_number: seller.mobileNumber,
        person_id: seller.personId,
        correspondence_address: seller.correspondenceAddress,
      };
    },
  );

  let tenure: string = property.tenureOption;
  if (property.tenureOption === "Other / Not Sure" && property.tenureDetails) {
    tenure = property.tenureDetails;
  }

  const returnedTransaction = {
    _id: transaction._id,
    terms_and_conditions: true,
    primary_seller: transformedPrimarySeller,
    additional_sellers: transformedAdditionalSellers,
    property: {
      _id: property._id,
      line_1: property.propertyAddress.line_1,
      line_2: property.propertyAddress.line_2,
      line_3: property.propertyAddress.line_3,
      post_town: property.propertyAddress.post_town,
      country: property.propertyAddress.country,
      postcode: property.propertyAddress.postcode,
      county: property.propertyAddress.county,
      uprn: property.propertyAddress.uprn,
      district: property.propertyAddress.district,
      building_number: property.propertyAddress.building_number,
      sub_building_name: property.propertyAddress.sub_building_name,
      building_name: property.propertyAddress.building_name,
      premise: property.propertyAddress.premise,
      organisation_name: property.propertyAddress.organisation_name,
      po_box: property.propertyAddress.po_box,
      tenure,
    },
    onward_purchase: property.onwardPurchase,
    has_mortgage: property.hasMortgage,
    asking_price: parseFloat(property.askingPrice.replace(/,/g, "")),
    // TODO: when create transaction as development, update this to get the correct transaction type:
    type: "resale" as "resale" | "development",
  };

  return returnedTransaction;
};
