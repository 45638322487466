import { Card } from "@/components/Card/Card";
import { EstateAgent } from "../../../types";
import { Table, TableProps } from "../Table/Table";

type Personnel = EstateAgent["valuers"][0];

interface PersonnelDetailsProps {
  onEdit: () => void;
  title: string;
  personnelDetails: Personnel;
  keyPrefix: string;
}

export const PersonnelDetails = ({
  onEdit,
  title,
  personnelDetails,
  keyPrefix,
}: PersonnelDetailsProps) => {
  const getPersonnelDisplayFields = (personnel: Personnel): TableProps[] => {
    return [
      {
        label: "Name",
        value: `${personnel.first_name} ${personnel.last_name ? personnel.last_name : ""}`,
      },
      {
        label: "Email",
        value: personnel.email,
      },
      {
        label: "Phone Number",
        value: personnel.phone_number,
      },
    ];
  };

  const getTableKey = (label: string) => `${keyPrefix}-${label}`;

  return (
    <Card
      title={title}
      titleBackgroundColor="brand-heavy-warmth-25"
      titleColor="brand-heavy-teal-100"
      titleRightContent={
        <button
          className="text-brand-heavy-teal-100 underline"
          onClick={() => onEdit()}
        >
          Edit
        </button>
      }
    >
      <>
        {getPersonnelDisplayFields(personnelDetails).map(({ value, label }) => (
          <Table key={getTableKey(label)} label={label} value={value} />
        ))}
      </>
    </Card>
  );
};
