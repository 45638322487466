import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";

import Typography from "@/foundation/Typography/Typography";
import { Modal } from "../../../components/Modal/Modal";
import {
  AdminTransaction,
  LawyerGroupIds,
} from "../../pages/TransactionDetails/types";
import { CheckPropertyDetails } from "./CheckPropertyDetails";
import { ApiController } from "../../../service/ApiController";
import { CustomiseEmail } from "./CustomiseEmail";
import { ConfirmCard } from "./ConfirmCard";
import Icons from "@/foundation/Icons/Icons";
import ErrorText from "@/foundation/Typography/ErrorText";
import { AssignSolicitors } from "./AssignSolicitors";
import { LawyerGroup } from "../../../types";

interface AssignSolicitorsBlockProps {
  transaction: AdminTransaction;
  buyerLawyerGroupId?: string;
  sellerLawyerGroupId?: string;
  setTransaction: (transaction: AdminTransaction) => void;
  onCloseModal: () => void;
  sendSolicitorEmail: boolean;
}

const LawyerGroupFormSchema = z.object({
  buyer_lawyer_group: z.string().min(1, { message: "Solicitor is required" }),
  seller_lawyer_group: z.string().min(1, { message: "Solicitor is required" }),
  seller_solicitor_comments: z.string().optional(),
  buyer_solicitor_comments: z.string().optional(),
});

export type LawyerGroupFormState = z.infer<typeof LawyerGroupFormSchema>;

export const AssignSolicitorsBlock = ({
  transaction,
  buyerLawyerGroupId,
  sellerLawyerGroupId,
  setTransaction,
  onCloseModal,
  sendSolicitorEmail,
}: AssignSolicitorsBlockProps) => {
  const [displayConfirmCard, setDisplayConfirmCard] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [lawyerGroups, setLawyerGroups] = useState<LawyerGroup[]>([]);
  const [errorFetching, setErrorFetching] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchLawyerGroups() {
      try {
        const existingLawyerGroups = await ApiController.findAllLawyerGroups();

        setLawyerGroups(existingLawyerGroups);
      } catch (error) {
        if (error instanceof Error) {
          setErrorFetching(error.message);
        }
      }
    }

    fetchLawyerGroups();
  }, []);

  const getSelectedLawyerGroup = (
    lawyerGroupIdSelected: string,
  ): LawyerGroup | null => {
    return lawyerGroups.find((x) => x._id === lawyerGroupIdSelected) || null;
  };

  const handleConfirmAssignSolicitors = async (
    lawyerGroup: {
      buyerLawyerGroup: string;
      sellerLawyerGroup: string;
    },
    sellerSolicitorComments?: string,
    buyerSolicitorComments?: string,
  ) => {
    setIsInputDisabled(true);
    try {
      const sellerLawyerGroup = getSelectedLawyerGroup(
        lawyerGroup.sellerLawyerGroup,
      );
      const buyerLawyerGroup = getSelectedLawyerGroup(
        lawyerGroup.buyerLawyerGroup,
      );

      if (sellerLawyerGroup && buyerLawyerGroup) {
        const lawyerGroupsIds: LawyerGroupIds = {
          seller: sellerLawyerGroup._id,
          buyer: buyerLawyerGroup._id,
        };

        // Add lawyer group Ids to transaction in DB and send emails if required:
        const updatedTransaction =
          await ApiController.assignLawyerGroupTransaction(
            transaction._id,
            lawyerGroupsIds,
            sendSolicitorEmail,
            sellerSolicitorComments,
            buyerSolicitorComments,
          );

        setTransaction(updatedTransaction);

        setDisplayConfirmCard(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }

    setIsInputDisabled(false);
  };

  return (
    <Formik
      initialValues={{
        buyer_lawyer_group: buyerLawyerGroupId || "",
        seller_lawyer_group: sellerLawyerGroupId || "",
        seller_solicitor_comments: "",
        buyer_solicitor_comments: "",
      }}
      validate={withZodSchema(LawyerGroupFormSchema)}
      onSubmit={(values) => {
        const lawyerGroup = {
          buyerLawyerGroup: values.buyer_lawyer_group,
          sellerLawyerGroup: values.seller_lawyer_group,
        };
        return handleConfirmAssignSolicitors(
          lawyerGroup,
          values.seller_solicitor_comments,
          values.buyer_solicitor_comments,
        );
      }}
    >
      {({ handleSubmit }) => {
        return (
          <Form>
            <Modal
              title={
                sendSolicitorEmail
                  ? "Assign solicitors and send emails"
                  : "Assign solicitors with no emails"
              }
              cancelButtonFunction={() => onCloseModal()}
              saveButtonFunction={() => setDisplayConfirmCard(true)}
              hideSaveButton={displayConfirmCard}
            >
              <>
                <CheckPropertyDetails transaction={transaction} />

                <Typography
                  type="h3"
                  variant="h3"
                  weight="bold"
                  className="text-brand-heavy-teal-100 mb-[16px] mt-[40px]"
                >
                  Assign solicitors
                </Typography>

                {errorFetching ? (
                  <ErrorText>{errorFetching}</ErrorText>
                ) : (
                  <>
                    <AssignSolicitors
                      lawyerGroups={lawyerGroups}
                      isInputDisabled={isInputDisabled}
                      displayConfirmCard={displayConfirmCard}
                    />

                    {sendSolicitorEmail && (
                      <CustomiseEmail isDisabled={isInputDisabled} />
                    )}

                    <ErrorText>{error}</ErrorText>
                  </>
                )}

                {displayConfirmCard && (
                  <ConfirmCard
                    setDisplayConfirmCard={setDisplayConfirmCard}
                    handleConfirmAssign={handleSubmit}
                    extraInfo={
                      <div className="flex justify-center items-center p-[16px] bg-ui-warning-100 rounded-xl">
                        <div className="shrink-0 mr-[12px]">
                          <Icons iconName="Warning" size="24" />
                        </div>
                        {sendSolicitorEmail ? (
                          <Typography variant="sm" weight="semi-bold">
                            This will assign solicitors and send emails
                          </Typography>
                        ) : (
                          <Typography variant="sm" weight="semi-bold">
                            This will assign solicitors and{" "}
                            <b>not send emails</b>
                          </Typography>
                        )}
                      </div>
                    }
                    type="solicitors"
                  />
                )}
              </>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
