import * as Sentry from "@sentry/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Spinner } from "../../components/Spinner/Spinner";
import { Button } from "@/components/Button/Button";
import { ApiController } from "../../service/ApiController";
import { ErrorMessageWithEmail } from "../../components/Form/DetailsForm/AddressLookup/ErrorMessageWithEmail";

interface ReferralCodeCheckoutFormProps {
  transactionId: string;
  referralCode: string;
}

export const ReferralCodeCheckoutForm = ({
  transactionId,
  referralCode,
}: ReferralCodeCheckoutFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      await ApiController.updateReferralCodeInTransaction(
        transactionId,
        referralCode,
      );

      navigate("/sell/payment-success?code=true");
    } catch (error) {
      Sentry.captureException(error);
      setError(
        "There was an error submiting the form, please contact support at",
      );
    }

    setIsLoading(false);
  };

  return (
    <form id="referral-code-form" onSubmit={handleSubmit}>
      <div className="flex flex-col justify-between">
        <Button
          variant="primary"
          className="w-full mt-[24px]"
          disabled={isLoading || !referralCode || error !== ""}
          id="submit"
        >
          <span id="button-text">
            {isLoading ? (
              <span className="flex items-center">
                <Spinner /> Processing...
              </span>
            ) : (
              <div>Submit</div>
            )}
          </span>
        </Button>
        {error && <ErrorMessageWithEmail propertyErrorMessage={error} />}
      </div>
    </form>
  );
};
