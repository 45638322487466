import { Field, FieldProps } from "formik";

import { FieldSet } from "../../../components/Form/FieldSet/FieldSet";
import { Select } from "@/components/Input/Select";
import { Price } from "../../../components/Form/DetailsForm/Price/Price";
import Typography from "@/foundation/Typography/Typography";
import { ManualAddressForm } from "../../../components/Form/DetailsForm/AddressLookup/ManualAddressForm";

interface PropertyFormProps {
  transactionIndex: number;
}

export const PropertyForm = ({ transactionIndex }: PropertyFormProps) => {
  const getTypeOfPropertyOptions = () => {
    return [
      { label: "Select your option", value: "", disabled: true },
      { label: "Freehold", value: "Freehold" },
      { label: "Leasehold", value: "Leasehold" },
      { label: "Share of Freehold", value: "Share Of Freehold" },
    ];
  };

  return (
    <>
      <Typography type="h4" variant="h4" weight="bold">
        {`Unit ${transactionIndex + 1}`}
      </Typography>
      <div className="flex gap-[16px]">
        <div className="flex flex-col md:flex-row md:flex-wrap gap-[16px] w-[100%]">
          <div className="w-[100%] md:max-w-[320px]">
            <Price
              priceTitle="Asking price"
              typeOfPrice={`manualTransactions.${transactionIndex}.property.askingPrice`}
              placeholder="Asking price"
            />
          </div>
          <div className="w-[100%] md:max-w-[320px]">
            <div className="flex flex-col">
              <Typography
                type="h2"
                variant="xl"
                weight="bold"
                className="text-brand-heavy-teal-100 mb-[16px] mt-[32px]"
              >
                Property type
              </Typography>
              <Field
                name={`manualTransactions.${transactionIndex}.property.tenureOption`}
              >
                {({ field }: FieldProps) => {
                  return (
                    <Select
                      {...field}
                      name={`manualTransactions.${transactionIndex}.property.tenureOption`}
                      id={`manualTransactions.${transactionIndex}.property.tenureOption`}
                      options={getTypeOfPropertyOptions()}
                    />
                  );
                }}
              </Field>
            </div>
          </div>
        </div>
      </div>
      <FieldSet legend="Property Address">
        <div className="flex flex-col md:flex-row md:flex-wrap gap-[16px] w-[100%]">
          <ManualAddressForm
            namespace={`manualTransactions.${transactionIndex}.property.propertyAddress`}
          />
        </div>
      </FieldSet>
    </>
  );
};
