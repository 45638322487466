import { z } from "zod";
import { useState } from "react";
import { Field, FieldArray, Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";

import { Button } from "@/components/Button/Button";
import { Modal } from "../../../components/Modal/Modal";
import { Input } from "../../../components/Formik/Input/Input";
import { useEstateAgentContext } from "../../context/EstateAgentProvider";
import ErrorText from "@/foundation/Typography/ErrorText";

export const thirdfortForm = z.object({
  requestsSellerThirdfortChecks: z.boolean(),
  thirdfortRequesterEmails: z.array(z.string().email()),
});

interface EstateAgentThirdfortModalProps {
  onClose: () => void;
}

const blankInitialValues = {
  requestsSellerThirdfortChecks: false,
  thirdfortRequesterEmails: [],
};

export const EstateAgentThirdfortModal = ({
  onClose,
}: EstateAgentThirdfortModalProps) => {
  const { estateAgent, updateEstateAgent, loading } = useEstateAgentContext();

  const [error, setError] = useState("");

  const onSubmit = async (values: z.infer<typeof thirdfortForm>) => {
    const { error } = await updateEstateAgent!({
      ...estateAgent!,
      thirdfort: values,
    });

    if (error) {
      setError(error);
    } else {
      onClose();
    }
  };

  return (
    <Formik
      initialValues={
        estateAgent?.thirdfort
          ? {
              ...estateAgent.thirdfort,
              thirdfortRequesterEmails: estateAgent.thirdfort
                .thirdfortRequesterEmails
                ? estateAgent.thirdfort.thirdfortRequesterEmails
                : [],
            }
          : blankInitialValues
      }
      validate={withZodSchema(thirdfortForm)}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit, setFieldTouched }) => (
        <Modal
          title="Thirdfort"
          cancelButtonFunction={onClose}
          saveButtonFunction={handleSubmit}
          isSaveButtonDisabled={loading}
        >
          <div className="mb-[24px]">
            <label className="flex items-center text-black">
              <Field
                type="checkbox"
                name="primary_valuer"
                checked={values.requestsSellerThirdfortChecks}
                onChange={() => {
                  const newValue = !values.requestsSellerThirdfortChecks;
                  setFieldValue("requestsSellerThirdfortChecks", newValue);
                  if (newValue && values.thirdfortRequesterEmails.length < 1) {
                    setFieldValue("thirdfortRequesterEmails", [""]);
                  }
                  if (
                    !newValue &&
                    values.thirdfortRequesterEmails.length === 1 &&
                    values.thirdfortRequesterEmails[0] === ""
                  ) {
                    setFieldValue("thirdfortRequesterEmails", []);
                  }
                  setFieldTouched("thirdfortRequesterEmails", false);
                }}
              />
              <span className="ml-2">Office receives Thirdfort checks?</span>
            </label>
          </div>

          <div className="mb-[8px]">
            <FieldArray name="thirdfortRequesterEmails">
              {({ remove, push }) => {
                const removeButtonDisabled =
                  values.thirdfortRequesterEmails.length === 1 &&
                  values.requestsSellerThirdfortChecks;
                return (
                  <div>
                    {values.thirdfortRequesterEmails.length > 0 &&
                      values.thirdfortRequesterEmails.map((_, index) => (
                        <div key={index} className="flex mb-[8px]">
                          <Input
                            fieldName={`thirdfortRequesterEmails.${index}`}
                            label="Requester email address"
                          />
                          <button
                            className={`ml-[16px] mt-[-24px] ${removeButtonDisabled ? "cursor-not-allowed text-ui-black-50" : "cursor-pointer"}`}
                            onClick={() => remove(index)}
                            disabled={removeButtonDisabled}
                          >
                            X
                          </button>
                        </div>
                      ))}
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => push("")}
                      className="mt-[16px] w-[160px]"
                    >
                      Add email address
                    </Button>
                  </div>
                );
              }}
            </FieldArray>
          </div>
          {error && <ErrorText>{error}</ErrorText>}
        </Modal>
      )}
    </Formik>
  );
};
