import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { Table, TableProps } from "../Table/Table";
import { AddRepresentativeDetails } from "./AddRepresentativeDetails";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import { initialTransactionsGroupStateObj } from "./initialDetails";

export const RepresentativeDetailsBlock = () => {
  const { setRepresentative, representative } = useTransactionsGroupContext();

  const [fields, setFields] = useState<TableProps[]>([]);
  const [
    displayRepresentativeDetailsForm,
    setDisplayRepresentativeDetailsForm,
  ] = useState(false);

  useEffect(() => {
    if (representative) {
      const updatedFields = [
        { label: "First name", value: representative.firstName },
        { label: "Middle Name", value: representative.middleName || "" },
        { label: "Last Name", value: representative.lastName },
        { label: "Email", value: representative.email },
        { label: "Phone number", value: representative.mobileNumber },
        {
          label: "Address Line 1",
          value: representative.correspondenceAddress?.line_1 || "",
        },
        {
          label: "Address Line 2",
          value: representative.correspondenceAddress?.line_2 || "",
        },
        {
          label: "Address Line 3",
          value: representative.correspondenceAddress?.line_3 || "",
        },
        {
          label: "Post Town",
          value: representative.correspondenceAddress?.post_town || "",
        },
        {
          label: "Country",
          value: representative.correspondenceAddress?.country || "",
        },
        {
          label: "Postcode",
          value: representative.correspondenceAddress?.postcode || "",
        },
      ];
      setFields(updatedFields);
    }
  }, [representative]);

  return (
    <Card
      title="Representative Details"
      backgroundColour="bg-brand-heavy-warmth-25"
    >
      {representative?.email ? (
        <div className="w-full">
          <Card
            titleColor="brand-heavy-teal-100"
            titleBackgroundColor="brand-heavy-warmth-50"
            title="User Details"
            titleRightContent={
              <button
                onClick={() => {
                  setRepresentative!(
                    initialTransactionsGroupStateObj.representative,
                  );
                }}
              >
                Delete
              </button>
            }
          >
            <div>
              {fields.map(({ value, label, includeCopyButton }) => (
                <div key={label} className="flex jusify-top">
                  <Table
                    key={value as string}
                    label={label}
                    value={value}
                    includeCopyButton={includeCopyButton}
                  />
                </div>
              ))}
            </div>
          </Card>
        </div>
      ) : (
        <AddRepresentativeDetails
          setDisplayRepresentativeDetailsForm={
            setDisplayRepresentativeDetailsForm
          }
          displayRepresentativeDetailsForm={displayRepresentativeDetailsForm}
        />
      )}
    </Card>
  );
};
