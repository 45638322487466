import { useEffect, useRef } from "react";
import { useFormikContext } from "formik";

import { Button } from "@/components/Button/Button";
import { PropertyForm } from "../TransactionsGroupDetailsBlocks/PropertyDetailsForm";
import { GroupTransactionsState, ManualTransactionState } from "@schema";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";

interface AddPropertyDetailsProps {
  transactionIndex: number;
  onDuplicatePrevious: () => void;
  onRemove: (index: number) => void;
}

const AddPropertyDetailsEffect = ({
  transactionIndex,
}: {
  transactionIndex: number;
}) => {
  const { values } = useFormikContext<GroupTransactionsState>();
  const { updateManualTransaction } = useTransactionsGroupContext();

  const currentUnit = values.manualTransactions[transactionIndex];
  const previousUnit = useRef(values.manualTransactions[transactionIndex]);

  const hasUnitValuesChanged = (
    current: ManualTransactionState,
    previous: ManualTransactionState,
  ) => {
    return JSON.stringify(current) !== JSON.stringify(previous);
  };

  useEffect(() => {
    // Update context if the some input has changed:
    if (hasUnitValuesChanged(currentUnit, previousUnit.current)) {
      updateManualTransaction!(currentUnit, transactionIndex);
      previousUnit.current = currentUnit;
    }
  }, [currentUnit, transactionIndex, updateManualTransaction]);

  return null;
};

export const AddPropertyDetails = ({
  transactionIndex,
  onDuplicatePrevious,
  onRemove,
}: AddPropertyDetailsProps) => {
  const { values } = useFormikContext<GroupTransactionsState>();

  return (
    <div className="w-full flex flex-col">
      <AddPropertyDetailsEffect transactionIndex={transactionIndex} />
      <PropertyForm transactionIndex={transactionIndex} />
      <div className="flex">
        {transactionIndex === values.manualTransactions.length - 1 && (
          <Button
            size="2xl"
            variant="primary"
            type="submit"
            onClick={onDuplicatePrevious}
            className="w-full md:w-[200px] h-[64px] my-[16px] mr-[16px]"
          >
            Duplicate unit
          </Button>
        )}

        {values.manualTransactions.length > 1 && (
          <Button
            size="2xl"
            variant="secondary"
            type="button"
            onClick={() => onRemove(transactionIndex)}
            className="w-full md:w-[200px] h-[64px] my-[16px]"
          >
            Remove
          </Button>
        )}
      </div>
    </div>
  );
};
