import * as Sentry from "@sentry/react";
import { Formik, FormikHelpers } from "formik";
import { withZodSchema } from "formik-validator-zod";

import { ApiController } from "../../../service/ApiController";
import { Button } from "@/components/Button/Button";
import { initialLoginState } from "../../../store/Login/login";
import { Input } from "../../Formik/Input/Input";

import { useRecaptcha } from "@utils";
import { SignInSchema, SignInType } from "@schema";
import { useSetQueryParam } from "@hooks";

interface SignInFormProps {
  state: SignInType;
  onSubmit: (values: SignInType) => void;
}

export const SignInForm = ({ onSubmit }: SignInFormProps) => {
  const recaptchaContext = useRecaptcha();
  const setQueryParam = useSetQueryParam();

  const handleSubmit = async (
    values: SignInType,
    formikActions: FormikHelpers<SignInType>,
  ) => {
    try {
      if (!recaptchaContext?.executeRecaptcha) {
        setQueryParam("networkError", "true");
        throw new Error("reCAPTCHA context is not available");
      }

      const token = await recaptchaContext.executeRecaptcha("login");
      if (!token) {
        Sentry.captureException(new Error("reCAPTCHA token is not available"));
        return;
      }

      await ApiController.verifyRecaptcha(token);
      onSubmit(values);
      formikActions.resetForm({ values: { email: "" } });
    } catch (error: unknown) {
      if (error instanceof Error) {
        Sentry.captureException(error);
        console.error(error.message);
      } else {
        console.error("An unexpected reCAPTCHA error occurred", error);
      }
    }
  };

  return (
    <Formik
      initialValues={{ email: initialLoginState.email }}
      onSubmit={handleSubmit}
      validate={withZodSchema(SignInSchema)}
      validateOnMount
    >
      {(formik) => (
        <form
          onSubmit={formik.handleSubmit}
          data-testid="login-form"
          className="flex flex-col flex-start w-[100%] max-w-[400px] pt-[40px]"
        >
          <div className="mb-[16px] ">
            <Input fieldName="email" label="Email address" />
          </div>

          <Button
            variant="primary"
            size="full"
            type="submit"
            disabled={!formik.isValid}
            className="text-brand-sand-100 mb-[24px]"
          >
            Send me a magic link
          </Button>
        </form>
      )}
    </Formik>
  );
};
