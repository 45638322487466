import { FieldArray, Formik, FormikProps } from "formik";
import { withZodSchema } from "formik-validator-zod";

import { AddPropertyDetails } from "./AddPropertyDetails";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import { initialTransactionsGroupStateObj } from "./initialDetails";
import { manualTransactionsSchema } from "../../../schema/TransactionsGroupSchema";
import { Card } from "@/components/Card/Card";
import { ManualTransactionsState } from "@schema";

export const PropertyDetailsBlock = () => {
  const {
    manualTransactions,
    addManualTransaction,
    removeManualTransaction,
    resetManualTransactions,
  } = useTransactionsGroupContext();

  const setFieldsTouched = (
    formik: FormikProps<ManualTransactionsState>,
    index: number,
  ) => {
    const fields = [
      `manualTransactions.${index}.property.askingPrice`,
      `manualTransactions.${index}.property.propertyAddress.line_1`,
      `manualTransactions.${index}.property.propertyAddress.line_2`,
      `manualTransactions.${index}.property.propertyAddress.line_3`,
      `manualTransactions.${index}.property.propertyAddress.post_town`,
      `manualTransactions.${index}.property.propertyAddress.country`,
      `manualTransactions.${index}.property.propertyAddress.postcode`,
    ];

    fields.forEach((field) => {
      formik.setFieldTouched(field, true);
    });
  };

  return (
    <Formik
      initialValues={
        { manualTransactions: manualTransactions } ||
        initialTransactionsGroupStateObj
      }
      onSubmit={() => {}}
      validate={withZodSchema(manualTransactionsSchema)}
      validateOnMount
    >
      {(formik) => {
        return (
          <Card
            title="Property Details"
            backgroundColour="bg-brand-heavy-warmth-25"
            titleRightContent={
              <button
                className={`text-ui-white-100 ${manualTransactions.length <= 1 && "hidden"}`}
                onClick={() => {
                  formik.setFieldValue("manualTransactions", []);
                  resetManualTransactions!();
                }}
              >
                Remove all
              </button>
            }
          >
            <FieldArray name="manualTransactions">
              {({ remove, push }) => (
                <>
                  {manualTransactions?.map((_, index) => (
                    <div key={index}>
                      <AddPropertyDetails
                        transactionIndex={index}
                        onDuplicatePrevious={async () => {
                          const containErrors = await formik.validateForm();
                          setFieldsTouched(formik, index);
                          if (!Object.keys(containErrors).length) {
                            const newTx = {
                              ...formik.values.manualTransactions[index],
                              events: [],
                              orders: [],
                            };
                            push(newTx);

                            addManualTransaction!(newTx, index);
                          }
                        }}
                        onRemove={() => {
                          remove(index);
                          removeManualTransaction!(index);
                        }}
                      />
                      {index !== manualTransactions.length - 1 && (
                        <hr className="flex border-t-2 border-brand-heavy-warmth-75 my-[20px]" />
                      )}
                    </div>
                  ))}
                </>
              )}
            </FieldArray>
          </Card>
        );
      }}
    </Formik>
  );
};
