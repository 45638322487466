import {
  CompanySellerState,
  ManualTransactionPropertyState,
  PersonalDetailsWithAddressState,
} from "@schema";
import { initialPersonalDetailsStateObj } from "../../../store/initialDetails";
import { Order } from "../../pages/TransactionDetails/types";

export type ManualTransactionFormState = {
  _id: string;
  property: ManualTransactionPropertyState;
  orders?: Order[];
  events?: Event[];
};

export type GroupTransactionsFormState = {
  manualTransactions: ManualTransactionFormState[];
  representative: PersonalDetailsWithAddressState;
  companySeller: CompanySellerState;
};

export const initialCompanySellerStateObj: CompanySellerState = {
  name: "",
  companyNumber: "",
  address: {
    line_1: "",
    line_2: "",
    line_3: "",
    post_town: "",
    country: "",
    postcode: "",
  },
};

export const initialManualTransactionPropertyDetailsStateObj: ManualTransactionPropertyState =
  {
    askingPrice: "",
    tenureOption: "Freehold",
    propertyAddress: {
      line_1: "",
      line_2: "",
      line_3: "",
      post_town: "",
      country: "",
      postcode: "",
      county: "",
      uprn: "",
      district: "",
      building_name: "",
      building_number: "",
      sub_building_name: "",
      premise: "",
      organisation_name: "",
      po_box: "",
    },
  };

export const initialManualTransactionsStateArray: ManualTransactionFormState[] =
  [
    {
      _id: "",
      property: initialManualTransactionPropertyDetailsStateObj,
      orders: [],
      events: [],
    },
  ];

export const initialTransactionsGroupStateObj: GroupTransactionsFormState = {
  manualTransactions: initialManualTransactionsStateArray,
  representative: {
    ...initialPersonalDetailsStateObj,
    primaryCustomer: true,
  },
  companySeller: initialCompanySellerStateObj,
};
