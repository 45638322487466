import { ComponentProps } from "react";

import Typography from "../../foundation/Typography/Typography";

export type OtherSelectableCardProps = ComponentProps<"div"> & {
  handleChange: (option: string) => void;
  label?: string;
  option: string;
  radioName: string;
  value: string;
  variant?: "enabled" | "disabled" | "error" | "success";
};

const OtherSelectableCard = ({
  variant = "enabled",
  label,
  radioName,
  value,
  option,
  handleChange,
  ...props
}: OtherSelectableCardProps) => (
  <label htmlFor={option}>
    <div
      {...props}
      className={`flex min-h-[96px] min-w-[70px] px-[16px] leading-default cursor-pointer items-center justify-center text-base font-normal rounded-2xl focus:outline-none   ${
        variant === "disabled" ? "cursor-not-allowed" : "cursor-pointer"
      } 
        ${props.className}`}
      onClick={() => variant !== "disabled" && handleChange(option)}
    >
      <input
        type="radio"
        name={radioName}
        disabled={variant === "disabled"}
        className="hidden"
        value={value}
        onChange={() => handleChange(option)}
      />
      <Typography type="p" variant="base" weight="regular">
        {label}
      </Typography>
    </div>
  </label>
);

export default OtherSelectableCard;
