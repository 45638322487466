import * as Sentry from "@sentry/react";

import { api } from "../../../service/ApiController";
import { paths } from "@shared/types.d";

export type TransactionsGroupsResponse =
  paths["/api/transactions-group"]["get"]["responses"]["200"]["content"]["application/json"];

export const findAllTransactionsGroups =
  async (): Promise<TransactionsGroupsResponse> => {
    try {
      const response = await api.get("/transactions-group");
      return response.data;
    } catch (error) {
      Sentry.captureException(error);

      throw new Error("There was an error fetching transactions groups");
    }
  };
