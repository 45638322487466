import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

export type UpdateEstateAgentResponse =
  paths["/api/estate-agent/{id}"]["put"]["responses"]["200"]["content"]["application/json"];

export type UpdateEstateAgentRequest =
  paths["/api/estate-agent/{id}"]["put"]["requestBody"]["content"]["application/json"];

export const updateEstateAgent = async (
  id: string,
  updatedEstateAgent: UpdateEstateAgentRequest,
): Promise<UpdateEstateAgentResponse> => {
  try {
    const response = await api.put(`/estate-agent/${id}`, updatedEstateAgent);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Error creating estate agent");
  }
};
