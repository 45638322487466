import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { PersonalDetailsWithAddressState } from "../../schema";

import { PageTemplate } from "../../components/Form/PageTemplate/PageTemplate";
import { useAuthStore } from "../../store/auth/auth";
import { ErrorMessageWithEmail } from "../../components/Form/DetailsForm/AddressLookup/ErrorMessageWithEmail";
import { sellAboutYou } from "../../components/Header/allStepperOptions";
import { AboutYouForm } from "../../components/Form/DetailsForm/AboutYouForm/AboutYouForm";
import { useTransactionFormStore } from "../../store/TransactionForm/transactionForm";

export const SellerAboutYou = () => {
  const { transaction, updateTransaction } = useTransactionFormStore();
  const currentUser = useAuthStore();
  const navigate = useNavigate();

  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (!currentUser.userData?.email) {
      Sentry.captureException(
        "No user email found, preventing seller form load.",
      );

      setError(
        "There was an error with loading the form details, please contact support at",
      );
    }
  }, [currentUser.userData?.email]);

  const handleFormSubmit = (values: PersonalDetailsWithAddressState) => {
    updateTransaction({
      primarySeller: values,
    });

    navigate("/sell/about-the-property");
  };

  return (
    <PageTemplate title="About you" stepperOptions={sellAboutYou}>
      {error ? (
        <ErrorMessageWithEmail propertyErrorMessage={error} />
      ) : (
        <AboutYouForm
          state={{
            ...transaction.primarySeller,
            email: currentUser.userData?.email || "",
            personId: currentUser.userData?._id || "",
          }}
          onSubmit={handleFormSubmit}
        />
      )}
    </PageTemplate>
  );
};
