import { useState } from "react";

import { EstateAgentValuerModal } from "../Modals/EstateAgentValuerModal";
import { useEstateAgentContext } from "../../context/EstateAgentProvider";
import { EstateAgent } from "../../../types";
import { AddPersonnel } from "./AddPersonnel";

type Valuer = EstateAgent["valuers"][0];

export const AddValuer = () => {
  const { estateAgent, updateEstateAgent } = useEstateAgentContext();

  const [showValuerModal, setShowValuerModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmitNewValuer = async (newValuer: Valuer) => {
    const { error } = await updateEstateAgent!({
      ...estateAgent!,
      valuers: [
        ...(estateAgent?.valuers.map((v) => ({
          ...v,
          primary_valuer: !newValuer.primary_valuer,
        })) || []),
        newValuer,
      ],
    });

    if (error) {
      setErrorMessage(error);
    } else {
      setShowValuerModal(false);
    }
  };

  return (
    <>
      <AddPersonnel
        buttonLabel="Add valuer"
        onButtonClick={() => setShowValuerModal(true)}
      />
      {showValuerModal && (
        <EstateAgentValuerModal
          onClose={() => setShowValuerModal(false)}
          onSubmit={onSubmitNewValuer}
          errorMessage={errorMessage}
          initialValues={{
            first_name: "",
            primary_valuer: estateAgent?.valuers.length === 0,
          }}
          disablePrimaryValuer={estateAgent?.valuers.length === 0}
        />
      )}
    </>
  );
};
