import { Table } from "../Table/Table";
import { Card } from "@/components/Card/Card";
import { formatCurrency } from "@shared/utils";
import { useTransactionContext } from "../../context/TransactionProvider";

export const OrdersTab = () => {
  const { transaction } = useTransactionContext();

  return (
    <>
      {transaction?.orders.map((order, orderIndex) => (
        <div key={`order-${orderIndex}`}>
          <Card
            title={`Order ${orderIndex + 1}`}
            titleBackgroundColor="brand-heavy-warmth-25"
            titleColor="brand-heavy-teal-100"
          >
            <>
              {order.items.map((item, itemIndex) => (
                <div key={`order-${orderIndex}-${itemIndex}`}>
                  <Table
                    label={item.product_name}
                    value={formatCurrency(item.product_price)}
                  />
                </div>
              ))}
              <hr className="text-brand-heavy-warmth-75 my-[20px]" />
              <Table
                label="Total Amount"
                value={formatCurrency(order.total_amount)}
              />
              <div className="mb-[32px]"></div>
              <Table
                label="Stripe Transcaction Id"
                value={order.stripe_transaction_id}
                isSmall
              />
              <Table
                label="Stripe Customer Id"
                value={order.stripe_customer_id}
                isSmall
              />
            </>
          </Card>
        </div>
      ))}
    </>
  );
};
