import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Nav } from "../../components/Nav/Nav";
import { OverviewSeller } from "../../components/TermsAndConditions/OverviewSeller";
import { OverviewBuyer } from "../../components/TermsAndConditions/OverviewBuyer";
import { TermsAndConditionsOfUse } from "../../components/TermsAndConditions/TermsAndConditionsOfUse";
import { buyTerms, sellTerms } from "../../components/Header/allStepperOptions";
import { Background } from "../../components/Background/Background";
import { Container } from "../../components/Container/Container";
import StepperGroup from "@/components/Stepper/StepperGroup";
import { useTransactionFormStore } from "../../store/TransactionForm/transactionForm";
import { ApiController } from "../../service/ApiController";
import { useLoginStore } from "../../store/Login/login";
import { usePurchaseIntentFormStore } from "../../store/PurchaseIntentForm/purchaseIntentForm";
import {
  transformTransactionPropertyState,
  transformTransactionSellerState,
  transformUpdateTransactionFormForTransactionUpdateApi,
  transformCreateTransactionFormForTransactionCreateApi,
  transformUpdatePrimarySellerForUpdateCustomerApi,
} from "@transformers";
import { ErrorMessageWithEmail } from "../../components/Form/DetailsForm/AddressLookup/ErrorMessageWithEmail";

export const TermsAndConditions = () => {
  const [error, setError] = useState<string>("");
  const { transaction, updateTransaction } = useTransactionFormStore();
  const { purchaseIntent, updatePurchaseIntent } = usePurchaseIntentFormStore();
  const { role } = useLoginStore();
  const navigate = useNavigate();

  const handleFormSubmit = async () => {
    try {
      if (role === "sell") {
        handleTransaction();
      } else {
        handlePurchaseIntent();
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(`${error.message}. Please contact support at`);
      }
    }
  };

  const handleTransaction = async () => {
    let existingTransaction;
    if (transaction._id !== "") {
      existingTransaction = await ApiController.findTransaction(
        transaction._id,
      );
    }
    if (existingTransaction) {
      handleUpdateTransaction();
    } else {
      handleCreateTransaction();
    }
  };

  const handleUpdateTransaction = async () => {
    // Update customer (primary seller):
    const transformedPrimarySeller =
      transformUpdatePrimarySellerForUpdateCustomerApi(
        transaction.primarySeller,
      );
    const updatedCustomer = await ApiController.updateUser(
      transformedPrimarySeller,
    );

    // Update transaction:
    const transformedTransaction =
      transformUpdateTransactionFormForTransactionUpdateApi(
        transaction,
        updatedCustomer,
      );

    await ApiController.updateTransaction(transformedTransaction);
    navigate("/sell/payment");
  };

  const handleCreateTransaction = async () => {
    transaction.primarySeller.termsAndConditions = true;

    // Update customer (primary seller):
    const transformedPrimarySeller =
      transformUpdatePrimarySellerForUpdateCustomerApi(
        transaction.primarySeller,
      );
    const updatedCustomer = await ApiController.updateUser(
      transformedPrimarySeller,
    );

    // Create transaction:
    const transformedTransaction =
      transformCreateTransactionFormForTransactionCreateApi(
        transaction,
        updatedCustomer,
      );
    const createdTransaction = await ApiController.createTransaction(
      transformedTransaction,
    );

    if (createdTransaction) {
      const transformedPrimarySeller = transformTransactionSellerState(
        createdTransaction.sellers,
      );
      const transformedProperty =
        transformTransactionPropertyState(createdTransaction);
      updateTransaction({
        ...createdTransaction,
        primarySeller: transformedPrimarySeller,
        property: transformedProperty,
      });
      navigate("/sell/payment");
    }
  };

  const handlePurchaseIntent = async () => {
    if (purchaseIntent._id) {
      await ApiController.updatePurchaseIntent(
        purchaseIntent._id,
        purchaseIntent,
      );
    } else {
      purchaseIntent.primaryBuyer.termsAndConditions = true;
      const createdPurchaseIntent =
        await ApiController.createPurchaseIntent(purchaseIntent);

      updatePurchaseIntent({
        ...purchaseIntent,
        _id: createdPurchaseIntent.purchaseIntent._id,
      });
    }
    navigate("/buy/whats-included");
  };

  return (
    <Background className="flex flex-col">
      <Nav />

      <Container>
        <div className="flex flex-col px-[24px] lg:px-0">
          <Header title="Our Terms & Conditions">
            <StepperGroup
              options={role === "buy" ? buyTerms : sellTerms}
            ></StepperGroup>
          </Header>

          {error ? (
            <ErrorMessageWithEmail propertyErrorMessage={error} />
          ) : (
            <>
              <div className="flex flex-col md:flex-row">
                <div>
                  {role === "sell" ? <OverviewSeller /> : <OverviewBuyer />}
                </div>

                <TermsAndConditionsOfUse />
              </div>

              <Footer
                handleBack={() => navigate(`/${role}/about-the-property`)}
                handleContinue={handleFormSubmit}
                continueTitle="Agree and Continue"
                hasValidation={false}
              />
            </>
          )}
        </div>
      </Container>
    </Background>
  );
};
