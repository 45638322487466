import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import ErrorText from "@/foundation/Typography/ErrorText";
import Typography from "@/foundation/Typography/Typography";
import { Card } from "@/components/Card/Card";

import { ApiController } from "../../../service/ApiController";
import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { Table, TableProps } from "../../components/Table/Table";
import { LawyerGroupThirdfort } from "../../components/LawyerGroupDetails/LawyerGroupThirdfort";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { LawyerGroup } from "../../../types";

export const LawyerGroupDetails = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [lawyerGroup, setLawyerGroup] = useState<LawyerGroup>();
  const [lawFirmDetails, setLawFirmDetails] = useState<TableProps[]>([]);
  const [solicitorDetails, setSolicitorDetails] = useState<TableProps[]>([]);
  const [legalAssistantDetails, setLegalAssistantDetails] = useState<
    TableProps[][]
  >([]);

  useEffect(() => {
    async function fetchData(id: string) {
      try {
        setLoading(true);
        setError("");

        const response = await ApiController.findLawyerGroup(id);
        setLawyerGroupDetails(response);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("There was an error fetching the data");
        }
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      fetchData(id);
    }
  }, [id]);

  const setLawyerGroupDetails = (response: LawyerGroup) => {
    setLawyerGroup(response);
    setLawFirmDetails([
      { label: "Name", value: response.name },
      { label: "Law firm name", value: response.law_firm.name },
    ]);

    setSolicitorDetails([
      { label: "First name", value: response.solicitor.first_name },
      { label: "Last name", value: response.solicitor.last_name },
      { label: "Email", value: response.solicitor.email },
      {
        label: "Phone number",
        value: response.solicitor.lawyer_profile?.phone_number,
      },
    ]);

    setLegalAssistantDetails(
      response.legal_assistants?.map((assistant) => [
        { label: "First name", value: assistant.first_name },
        { label: "Last name", value: assistant.last_name },
        { label: "Email", value: assistant.email },
        {
          label: "Phone number",
          value: assistant.lawyer_profile?.phone_number,
        },
      ]) || [],
    );
  };

  return (
    <>
      <AdminTitle title="Lawyer Group Details" />
      {loading && <FullPageLoader />}
      {error && <ErrorText>{error}</ErrorText>}

      {lawyerGroup && (
        <div className="flex flex-col space-y-5">
          <Card>
            <>
              {lawFirmDetails.map(({ label, value }) => (
                <Table key={label} label={label} value={value} />
              ))}
              <Link to={`/admin/law-firms/${lawyerGroup.law_firm._id}`}>
                <Typography
                  weight="semi-bold"
                  className="text-brand-heavy-teal-100 underline hover:text-brand-heavy-teal-75 cursor-pointer mt-[24px]"
                >
                  View more law firm details →
                </Typography>
              </Link>
            </>
          </Card>

          <Card title="Solicitor Details">
            <>
              {solicitorDetails.map(({ label, value }) => (
                <Table key={label} label={label} value={value} />
              ))}
            </>
          </Card>

          <Card title="Legal Assistants">
            <>
              {legalAssistantDetails.map((assistant, index) => (
                <Card
                  key={`legal-assistant-${index}`}
                  title={`Legal Assistant ${index + 1}`}
                  titleBackgroundColor="brand-heavy-warmth-25"
                  titleColor="brand-heavy-teal-100"
                >
                  <>
                    {assistant.map(({ value, label }) => (
                      <Table
                        key={`${label}-${index}`}
                        label={label}
                        value={value}
                      />
                    ))}
                  </>
                </Card>
              ))}
            </>
          </Card>
          <LawyerGroupThirdfort
            lawyerGroup={lawyerGroup}
            onUpdated={setLawyerGroupDetails}
          />
        </div>
      )}
    </>
  );
};
