import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

export type CreateLawFirmResponse =
  paths["/api/law-firm"]["post"]["responses"]["201"]["content"]["application/json"];

export type CreateLawFirmRequest =
  paths["/api/law-firm"]["post"]["requestBody"]["content"]["application/json"];

export const createLawFirm = async (
  newLawFirm: CreateLawFirmRequest,
): Promise<CreateLawFirmResponse> => {
  try {
    const response = await api.post(`/law-firm`, newLawFirm);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof AxiosError && error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
};
