import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ErrorText from "@/foundation/Typography/ErrorText";
import { ApiController } from "../../../service/ApiController";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { Table, TableRow } from "@/components/Table/Table";
import Typography from "@/foundation/Typography/Typography";

interface LawyerGroupTableRow {
  groupName: string;
  solicitorName: string;
  solicitorEmail: string;
  legalAssistantNames: string;
}

const columns = [
  { key: "groupName", title: "Group Name" },
  { key: "solicitorName", title: "Solicitor Name" },
  { key: "solicitorEmail", title: "Solicitor Email" },
  { key: "legalAssistantNames", title: "Legal Assistant Names" },
];

export const LawyerGroupTable = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [lawyerGroupDetails, setLawyerGroupDetails] = useState<
    TableRow<LawyerGroupTableRow>[]
  >([]);

  useEffect(() => {
    async function fetchData(id: string) {
      try {
        setLoading(true);
        setError("");

        const response = await ApiController.findAllLawyerGroups({
          law_firm: id,
        });

        setLawyerGroupDetails(
          response.map((lawyerGroup) => ({
            data: {
              groupName: lawyerGroup.name,
              solicitorName: `${lawyerGroup.solicitor.first_name} ${lawyerGroup.solicitor.last_name}`,
              solicitorEmail: lawyerGroup.solicitor.email,
              legalAssistantNames:
                lawyerGroup?.legal_assistants
                  ?.map((x) => `${x.first_name} ${x.last_name}`)
                  .join(", ") || "",
            },
            onClick: () => navigate(`/admin/lawyer-groups/${lawyerGroup._id}`),
          })),
        );
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("There was an error fetching the data");
        }
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      fetchData(id);
    }
  }, [id, navigate]);

  return (
    <>
      {loading && <FullPageLoader />}
      {error && <ErrorText>{error}</ErrorText>}
      <Typography
        variant="h4"
        weight="bold"
        className="my-[24px] text-brand-heavy-teal-100"
      >
        Lawyer Groups
      </Typography>
      <Table columns={columns} rows={lawyerGroupDetails} />
    </>
  );
};
