import { useState } from "react";
import { AdminSideNavigation } from "./AdminSideNavigation";
import { AdminProtectedRoute } from "../../../components/ProtectedRoute/AdminProtectedRoute";
import { Outlet } from "react-router-dom";

export const AdminPageTemplate = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <AdminProtectedRoute>
      <div className="w-full min-h-full">
        <AdminSideNavigation isOpen={isOpen} setIsOpen={setIsOpen} />

        <div
          className={`w-full flex-grow px-[8px] md:pr-[40px] mb-40 ${isOpen ? "md:pl-[260px]" : "md:pl-[100px]"}`}
        >
          <Outlet />
        </div>
      </div>
    </AdminProtectedRoute>
  );
};
