import { createContext, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

import { ApiController } from "../../service/ApiController";
import { TransactionsGroup } from "../../types";
import {
  CompanySellerState,
  ManualTransactionState,
  PersonalDetailsWithAddressState,
} from "@schema";
import {
  initialManualTransactionsStateArray,
  initialTransactionsGroupStateObj,
} from "../components/TransactionsGroupDetailsBlocks/initialDetails";

interface ApiResponse {
  error?: string;
  id?: string;
}

interface TransactionsGroupContext {
  transactionsGroup?: TransactionsGroup;
  setTransactionsGroup?: (newData: TransactionsGroup) => void;
  createTransactionsGroup?: () => Promise<ApiResponse>;
  representative?: PersonalDetailsWithAddressState;
  setRepresentative?: (newData: PersonalDetailsWithAddressState) => void;
  companySeller?: CompanySellerState;
  setCompanySeller?: (newData: CompanySellerState) => void;
  stripeTransactionId?: string;
  setStripeTransactionId?: (newData: string) => void;
  manualTransactions: ManualTransactionState[];
  setManualTransactions?: (newData: ManualTransactionState[]) => void;
  addManualTransaction?: (
    updatedManualTransaction: ManualTransactionState,
    index: number,
  ) => void;
  updateManualTransaction?: (
    updatedManualTransaction: ManualTransactionState,
    index: number,
  ) => void;
  resetManualTransactions?: () => void;
  removeManualTransaction?: (index: number) => void;
  loading?: boolean;
  error?: string;
}

export const TransactionsGroupContext = createContext<TransactionsGroupContext>(
  initialTransactionsGroupStateObj,
);

export const useTransactionsGroupContext = () => {
  const context = useContext(TransactionsGroupContext);
  if (!context) {
    throw new Error(
      "useTransactionsGroupContext must be used within a TransactionsGroupProvider",
    );
  }
  return context;
};

interface TransactionsGroupProviderProps {
  children: React.ReactNode;
  transactionsGroupId?: string;
}

export const TransactionsGroupProvider = ({
  children,
  transactionsGroupId,
}: TransactionsGroupProviderProps) => {
  const [transactionsGroup, setTransactionsGroup] = useState<TransactionsGroup>(
    () => {
      const savedGroup = localStorage.getItem("transactionsGroup");
      return savedGroup ? JSON.parse(savedGroup) : { name: "" };
    },
  );

  const [representative, setRepresentative] =
    useState<PersonalDetailsWithAddressState>(() => {
      const savedRep = localStorage.getItem("representative");
      return savedRep ? JSON.parse(savedRep) : undefined;
    });

  const [companySeller, setCompanySeller] = useState<CompanySellerState>(() => {
    const savedComp = localStorage.getItem("companySeller");
    return savedComp ? JSON.parse(savedComp) : undefined;
  });

  const [stripeTransactionId, setStripeTransactionId] = useState<string>("");

  const [manualTransactions, setManualTransactions] = useState<
    ManualTransactionState[]
  >(() => {
    const savedTransactions = localStorage.getItem("manualTransactions");
    return savedTransactions
      ? JSON.parse(savedTransactions)
      : initialManualTransactionsStateArray;
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    async function getTransactionsGroup(id: string) {
      try {
        setError("");
        setLoading(true);

        const transactionsGroupRes =
          await ApiController.findTransactionsGroup(id);

        setTransactionsGroup(transactionsGroupRes);
      } catch (error) {
        Sentry.captureException(error);
        setError(
          "There was an error while fetching the transaction group details",
        );
      } finally {
        setLoading(false);
      }
    }

    if (!transactionsGroupId) {
      setTransactionsGroup({
        name: "",
      });
    } else {
      getTransactionsGroup(transactionsGroupId);
    }
  }, [transactionsGroupId]);

  const createTransactionsGroup = async (): Promise<ApiResponse> => {
    try {
      setLoading(true);

      const newTransactionsGroup = await ApiController.createTransactionsGroup(
        transactionsGroup!,
      );

      setTransactionsGroup(newTransactionsGroup);
      return { id: newTransactionsGroup._id };
    } catch (error) {
      Sentry.captureException(error);
      return {
        error: "There was an error while adding the transaction group",
      };
    } finally {
      setLoading(false);
    }
  };

  const updateManualTransaction = (
    updatedManualTransaction: ManualTransactionState,
    index: number,
  ) => {
    // Get the index and update only that transaction:
    setManualTransactions((prev) =>
      prev.map((transaction, i) =>
        i === index
          ? { ...transaction, ...updatedManualTransaction }
          : transaction,
      ),
    );
  };

  const addManualTransaction = (
    updatedManualTransaction: ManualTransactionState,
    index: number,
  ) => {
    if (manualTransactions.length === 1) {
      // Set the first manual transaction:
      setManualTransactions([{ ...updatedManualTransaction }]);
    }
    setManualTransactions((prev) => {
      const updatedTransactions = [...prev];
      updatedTransactions[index + 1] = {
        ...updatedTransactions[index + 1],
        ...updatedManualTransaction,
      };
      return updatedTransactions;
    });
  };

  const removeManualTransaction = (index: number) => {
    setManualTransactions((prev) => prev.filter((_, idx) => idx !== index));
  };

  const resetManualTransactions = () => {
    setManualTransactions(initialManualTransactionsStateArray);
  };

  useEffect(() => {
    if (transactionsGroup) {
      localStorage.setItem(
        "transactionsGroup",
        JSON.stringify(transactionsGroup),
      );
    }
  }, [transactionsGroup]);

  useEffect(() => {
    if (representative && Object.keys(representative).length > 0) {
      localStorage.setItem("representative", JSON.stringify(representative));
    } else {
      localStorage.removeItem("representative");
    }
  }, [representative]);

  useEffect(() => {
    if (companySeller) {
      localStorage.setItem("companySeller", JSON.stringify(companySeller));
    } else {
      localStorage.removeItem("companySeller");
    }
  }, [companySeller]);

  useEffect(() => {
    if (manualTransactions.length) {
      localStorage.setItem(
        "manualTransactions",
        JSON.stringify(manualTransactions),
      );
    } else {
      localStorage.removeItem("manualTransactions");
    }
  }, [manualTransactions]);

  return (
    <TransactionsGroupContext.Provider
      value={{
        transactionsGroup,
        setTransactionsGroup,
        createTransactionsGroup,
        representative,
        setRepresentative,
        companySeller,
        setCompanySeller,
        manualTransactions,
        setManualTransactions,
        addManualTransaction,
        updateManualTransaction,
        resetManualTransactions,
        removeManualTransaction,
        stripeTransactionId,
        setStripeTransactionId,
        loading,
        error,
      }}
    >
      {children}
    </TransactionsGroupContext.Provider>
  );
};
