import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { Table, TableProps } from "../Table/Table";
import { useEstateAgentContext } from "../../context/EstateAgentProvider";

export const EstateAgentBlock = () => {
  const { estateAgent } = useEstateAgentContext();

  const [fields, setFields] = useState<TableProps[]>([]);

  useEffect(() => {
    setFields([
      {
        label: "ID",
        value: estateAgent?._id,
        includeCopyButton: true,
      },
      {
        label: "Office",
        value: `${estateAgent?.brand} - ${estateAgent?.office.name}`,
        includeCopyButton: true,
      },
    ]);
  }, [estateAgent]);

  return (
    <Card>
      <>
        {fields.map(({ value, label, includeCopyButton }) => (
          <div key={label} className="flex jusify-top">
            <Table
              key={value as string}
              label={label}
              value={value}
              includeCopyButton={includeCopyButton}
            />
          </div>
        ))}
      </>
    </Card>
  );
};
