import { TransactionEvents } from "../types/index";

export const isTransactionPaidOrReferralCodeApplied = (
  transactionEvents: TransactionEvents,
) => {
  const transactionPaid = transactionEvents.some(
    (event) => event.event_name === "payment_successful",
  );
  const transactionReferralCodeApplied = transactionEvents.some(
    (event) => event.event_name === "referral_code_applied",
  );

  return transactionPaid || transactionReferralCodeApplied;
};
