import { BuyerSellerBlock } from "../BuyerSellerBlock";
import { useTransactionContext } from "../../context/TransactionProvider";

export const SellersTab = () => {
  const { transaction } = useTransactionContext();

  return (
    <>
      {transaction?.sellers.map((seller, index) => (
        <BuyerSellerBlock
          key={`seller-${index}`}
          buyerSeller={seller}
          isSeller
        />
      ))}
    </>
  );
};
