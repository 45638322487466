import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";

import {
  SellerPropertyDetailsSchema,
  SellerPropertyDetailsState,
} from "../../../../schema";
import { Button } from "@/components/Button/Button";
import { Footer } from "../../../Footer/Footer";
import { relationshipToSaleOptions } from "../Fields";
import { TypeOfProperty } from "../TypeOfProperty/TypeOfProperty";
import { Price } from "../Price/Price";
import { RadioButtonQuestion } from "../RadioButtonQuestion/RadioButtonQuestion";
import { CustomerType } from "../CustomerType/CustomerType";
import { SellerPropertyAddress } from "../AddressLookup/SellerPropertyAddress";
import { AdditionalPersonSummaryCard } from "../../../AdditionalPerson/AdditionalPersonSummaryCard";
import { AddSellerModal } from "../AddPersonModal/AddSellerModal";
import { Roles } from "@shared/types";

interface SellerAboutThePropertyFormProps {
  state: SellerPropertyDetailsState;
  onSubmit: (values: SellerPropertyDetailsState) => void;
}

export const SellerAboutThePropertyForm = ({
  state,
  onSubmit,
}: SellerAboutThePropertyFormProps) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Formik
        initialValues={state}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validateOnMount
        validate={withZodSchema(SellerPropertyDetailsSchema)}
      >
        {(formik) => (
          <form
            className="flex flex-col items-start"
            onSubmit={formik.handleSubmit}
          >
            <SellerPropertyAddress />

            <Price
              priceTitle="Asking price"
              priceDescription="If the asking price is not yet confirmed please enter an reasonable estimate."
              typeOfPrice="askingPrice"
              placeholder="Asking price"
            />

            <TypeOfProperty />

            <RadioButtonQuestion
              title="Existing mortgage"
              description="Is there an existing mortgage on the property?"
              label="hasMortgage"
              options={["Yes", "No"]}
              selectedRadioButtonQuestionOption={
                formik.values.hasMortgage === null
                  ? null
                  : formik.values.hasMortgage
                    ? "Yes"
                    : "No"
              }
              setSelectedRadioButtonQuestionOption={(newValue) => {
                formik.setFieldValue(
                  "hasMortgage",
                  newValue === "Yes" ? true : false,
                );
              }}
              radioButtonQuestionError={
                formik.touched.hasMortgage ? formik.errors.hasMortgage : ""
              }
            />

            <RadioButtonQuestion
              title="Onward purchase"
              description="Is there an onward purchase that’s dependent on this sale?"
              label="onwardPurchase"
              options={["Yes", "No"]}
              selectedRadioButtonQuestionOption={
                formik.values.onwardPurchase === null
                  ? null
                  : formik.values.onwardPurchase
                    ? "Yes"
                    : "No"
              }
              setSelectedRadioButtonQuestionOption={(newValue) => {
                formik.setFieldValue(
                  "onwardPurchase",
                  newValue === "Yes" ? true : false,
                );
              }}
              radioButtonQuestionError={
                formik.touched.onwardPurchase
                  ? formik.errors.onwardPurchase
                  : ""
              }
            />

            <CustomerType
              relationshipToSaleOptions={relationshipToSaleOptions}
              selectedOption={formik.values.customerType || ""}
              setSelectedOption={(newValue) => {
                formik.setFieldValue("customerType", newValue);
              }}
              customerTypeError={
                formik.touched.customerType ? formik.errors.customerType : ""
              }
            />

            {/* Another Sellers */}

            <RadioButtonQuestion
              title="Other sellers or joint mortgage holders"
              description="If you not the only seller or are acting on behalf of a seller, select yes and add the additional seller’s details."
              label="additionalSeller"
              options={["Yes", "No"]}
              selectedRadioButtonQuestionOption={
                formik.values.isAnotherSellerSelected === null
                  ? null
                  : formik.values.isAnotherSellerSelected
                    ? "Yes"
                    : "No"
              }
              setSelectedRadioButtonQuestionOption={(newValue) => {
                formik.setFieldValue(
                  "isAnotherSellerSelected",
                  newValue === "Yes",
                );

                setTimeout(() =>
                  formik.setFieldTouched("isAnotherSellerSelected", true),
                );

                if (newValue === "Yes") {
                  setIsModalOpen(true);
                }
                if (newValue === "No") {
                  formik.setFieldValue("additionalSellers", []);
                }
              }}
              radioButtonQuestionError={
                formik.touched.isAnotherSellerSelected ||
                formik.values.isAnotherSellerSelected !== null
                  ? formik.errors.isAnotherSellerSelected
                  : ""
              }
            />

            {isModalOpen && (
              <AddSellerModal
                onCancel={() => {
                  setIsModalOpen(false);
                  formik.setFieldValue("isAnotherSellerSelected", null);
                }}
                onSaved={(newSeller) => {
                  setIsModalOpen(false);
                  formik.setFieldValue("additionalSellers", [
                    ...formik.values.additionalSellers,
                    newSeller,
                  ]);
                }}
              />
            )}

            {/* All Sellers Cards */}

            <div className="flex md:flex-row md:flex-wrap flex-col w-full mt-[40px]">
              {formik.values.additionalSellers.length > 0 &&
                formik.values.additionalSellers.map((seller, index) => (
                  <div key={`additional-seller-summary-${index}`}>
                    <AdditionalPersonSummaryCard
                      values={seller}
                      customer={Roles.Seller}
                      onDeletePerson={(id) => {
                        if (formik.values.additionalSellers.length <= 1) {
                          formik.setFieldValue("isAnotherSellerSelected", null);
                        }
                        formik.setFieldValue(
                          "additionalSellers",
                          formik.values.additionalSellers.filter(
                            (seller) => seller.personId !== id,
                          ),
                        );
                      }}
                    />
                  </div>
                ))}
            </div>

            {formik.values.additionalSellers.length > 0 && (
              <div className="flex w-full md:w-[400px]">
                <Button
                  variant="primary"
                  className="w-[320px]"
                  type="button"
                  onClick={() => setIsModalOpen(true)}
                  icon="PlusWhite"
                >
                  Add another seller
                </Button>
              </div>
            )}

            <Footer
              isValid={formik.isValid}
              handleBack={() => navigate("/sell/about-you")}
            />
          </form>
        )}
      </Formik>
    </>
  );
};
