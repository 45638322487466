import TextInput from "@/components/Input/TextInput";
import { TextInputIconProps } from "@/foundation/Icons/Icons";
import ErrorText from "@/foundation/Typography/ErrorText";
import { useField, useFormikContext } from "formik";

interface InputProps {
  fieldName: string;
  label: string;
  fieldType?: "text";
  success?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isCurrency?: boolean;
  icon?: TextInputIconProps;
}

const addCommasForThousands = (value: string | number | null): string => {
  if (!value) {
    return "";
  }

  if (typeof value === "number") {
    return value.toLocaleString("en-GB");
  } else {
    const numericValue = parseFloat(value.replace(/[^\d.]/g, ""));
    return numericValue.toLocaleString("en-GB");
  }
};

export const Input = ({
  fieldName,
  label,
  fieldType = "text",
  success,
  onChange,
  isCurrency = false,
  icon,
}: InputProps) => {
  const [field, meta] = useField(fieldName);
  const { touched } = meta;
  const { errors, setFieldValue } = useFormikContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const disallowedChars = /[^a-zA-Z0-9.,/@\-+ ?!"'()&;:_/]/g;

    // Replace disallowed characters with an empty string
    const sanitisedValue = value.replace(disallowedChars, "");

    setFieldValue(fieldName, sanitisedValue);

    const syntheticEvent = {
      ...e,
      target: {
        ...e.target,
        value: sanitisedValue,
      },
    };

    if (onChange) {
      onChange(syntheticEvent);
    }
  };

  return (
    <div>
      <TextInput
        {...field}
        label={label}
        type={fieldType}
        value={isCurrency ? addCommasForThousands(field.value) : field.value}
        variant={
          errors[fieldName as keyof typeof errors] && touched
            ? "error"
            : success
              ? "success"
              : "enabled"
        }
        onChange={handleChange}
        className="min-w-[320px]"
        onKeyDown={(e) => {
          if (
            isCurrency &&
            !(
              e.key === "Backspace" ||
              e.key === "Tab" ||
              e.key === "ArrowLeft" ||
              e.key === "ArrowRight"
            ) &&
            (e.key < "0" || e.key > "9")
          ) {
            e.preventDefault();
          }
        }}
        showIcon={icon || isCurrency ? true : false}
        icon={isCurrency ? "Currency" : icon}
      />

      <div className="h-[16px]">
        {errors[fieldName as keyof typeof errors] && touched && (
          <ErrorText>{errors[fieldName as keyof typeof errors]}</ErrorText>
        )}
      </div>
    </div>
  );
};
