import * as Sentry from "@sentry/react";

import { api } from ".";

export const confirmPurchaseIntent = async (
  purchaseIntent_id: string,
): Promise<void> => {
  try {
    const response = await api.get(
      `/purchase-intent/confirm/${purchaseIntent_id}`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(`Purchase intent confirmation failed`);
  }
};
