import * as Sentry from "@sentry/react";

import { api } from ".";
import { ContactFormFormikValues } from "../../components/Types";

export const requestCallback = async (
  values: ContactFormFormikValues,
): Promise<boolean> => {
  try {
    const response = await api.post("/email/request-callback", values);

    // Email has been sent successfully ie. code 201, return true
    return response.status === 201;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Request callback failed");
  }
};
