import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import ErrorText from "@/foundation/Typography/ErrorText";
import { Table, TableRow } from "@/components/Table/Table";
import { Button } from "@/components/Button/Button";

import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { ApiController } from "../../../service/ApiController";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { LawFirmModal } from "../../components/Modals/LawFirmModal";

interface LawFirmRow {
  name: string;
  email: string;
  phoneNumber: string;
}

const columns = [
  { key: "name", title: "Law firm name" },
  { key: "email", title: "Email" },
  { key: "phoneNumber", title: "Phone number" },
];

export const LawFirms = () => {
  const navigate = useNavigate();

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [tableRows, setTableRows] = useState<TableRow<LawFirmRow>[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const getLawFirms = useCallback(async () => {
    try {
      setLoading(true);
      setError("");
      const response = await ApiController.findAllLawFirms();

      setTableRows(
        response.map((lawFirm) => ({
          data: {
            name: lawFirm.name,
            email: lawFirm.email || "unknown",
            phoneNumber: lawFirm.phone_number,
          },
          onClick: () => navigate(`/admin/law-firms/${lawFirm._id}`),
        })),
      );
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("There was an error fetching the data");
      }
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    getLawFirms();
  }, [getLawFirms]);

  return (
    <>
      <div className="flex">
        <AdminTitle title="Law Firms" />
        <Button
          className="mt-[20px] md:mt-[40px]"
          variant="primary"
          onClick={() => setModalOpen(true)}
        >
          Add law firm
        </Button>
      </div>

      {loading && <FullPageLoader />}
      {error && <ErrorText>{error}</ErrorText>}
      <Table columns={columns} rows={tableRows} />
      {modalOpen && (
        <LawFirmModal
          onClose={() => setModalOpen(false)}
          onSaved={() => {
            setModalOpen(false);
            getLawFirms();
          }}
        />
      )}
    </>
  );
};
