import { createContext, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

import { ApiController } from "../../service/ApiController";
import { EstateAgent } from "../../types";

interface ApiResponse {
  error?: string;
  id?: string;
}

interface EstateAgentContext {
  estateAgent?: EstateAgent;
  setEstateAgent?: (newData: EstateAgent) => void;
  updateEstateAgent?: (newData: EstateAgent) => Promise<ApiResponse>;
  createEstateAgent?: () => Promise<ApiResponse>;
  loading?: boolean;
  error?: string;
  officeFieldsEmpty?: boolean;
}

export const EstateAgentContext = createContext<EstateAgentContext>({});

export const useEstateAgentContext = () => {
  const context = useContext(EstateAgentContext);
  if (!context) {
    throw new Error(
      "useEstateAgentContext must be used within a EstateAgentProvider",
    );
  }
  return context;
};

interface EstateAgentProviderProps {
  children: React.ReactNode;
  estateAgentId?: string;
}

export const EstateAgentProvider = ({
  children,
  estateAgentId,
}: EstateAgentProviderProps) => {
  const [estateAgent, setEstateAgent] = useState<EstateAgent>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [officeFieldsEmpty, setOfficeFieldsEmpty] = useState(false);

  useEffect(() => {
    async function getEstateAgent(id: string) {
      try {
        setError("");
        setLoading(true);

        const estateAgentRes = await ApiController.findEstateAgent(id);

        setEstateAgent(estateAgentRes);
      } catch (error) {
        Sentry.captureException(error);
        setError("There was an error while fetching the estate agent details");
      } finally {
        setLoading(false);
      }
    }

    if (!estateAgentId) {
      setEstateAgent({
        _id: "",
        brand: "",
        office: {
          name: "",
          address: {
            line_1: "",
            post_town: "",
            country: "",
            postcode: "",
          },
        },
        valuers: [],
        negotiators: [],
      });
    } else {
      getEstateAgent(estateAgentId);
    }
  }, [estateAgentId]);

  useEffect(() => {
    setOfficeFieldsEmpty(
      !estateAgent?.office?.name &&
        !estateAgent?.office?.address?.line_1 &&
        !estateAgent?.office?.address?.post_town &&
        !estateAgent?.office?.address?.country &&
        !estateAgent?.office?.address?.postcode,
    );
  }, [estateAgent]);

  const updateEstateAgent = async (
    newData: EstateAgent,
  ): Promise<ApiResponse> => {
    if (!estateAgentId) {
      setEstateAgent(newData);
      return {};
    }

    try {
      setLoading(true);

      const updatedEstateAgent = await ApiController.updateEstateAgent(
        estateAgentId,
        newData,
      );

      setEstateAgent(updatedEstateAgent);
      return {};
    } catch (error) {
      Sentry.captureException(error);
      return {
        error: "There was an error while updating the estate agent details",
      };
    } finally {
      setLoading(false);
    }
  };

  const createEstateAgent = async (): Promise<ApiResponse> => {
    try {
      setLoading(true);

      if (estateAgent?.valuers?.length === 0) {
        return {
          error: "Please add at least one valuer",
        };
      }

      if (officeFieldsEmpty) {
        return {
          error: "Please fill in the office details",
        };
      }

      const newEstateAgent = await ApiController.createEstateAgent(
        estateAgent!,
      );

      setEstateAgent(newEstateAgent);
      return { id: newEstateAgent._id };
    } catch (error) {
      Sentry.captureException(error);
      return {
        error: "There was an error while adding the estate agent",
      };
    } finally {
      setLoading(false);
    }
  };

  return (
    <EstateAgentContext.Provider
      value={{
        estateAgent,
        setEstateAgent,
        updateEstateAgent,
        createEstateAgent,
        loading,
        error,
        officeFieldsEmpty,
      }}
    >
      {children}
    </EstateAgentContext.Provider>
  );
};
