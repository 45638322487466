import { useField } from "formik";

import { Input } from "../../../Formik/Input/Input";
import { formatPostcode } from "@shared/utils";

interface ManualAddressFormProps {
  namespace: string;
}

export const ManualAddressForm = ({ namespace }: ManualAddressFormProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, _2, helpers] = useField<string>(`${namespace}.postcode`);

  const onSetPostcode = (postcode: string) => {
    helpers.setValue(postcode);
  };

  return (
    <>
      <Input fieldName={`${namespace}.line_1`} label="Address line 1" />
      <Input
        fieldName={`${namespace}.line_2`}
        label="Address Line Two (optional)"
      />
      <Input
        fieldName={`${namespace}.line_3`}
        label="Address Line Three (optional)"
      />
      <Input fieldName={`${namespace}.post_town`} label="Town" />
      <Input fieldName={`${namespace}.country`} label="Country" />
      <Input
        fieldName={`${namespace}.postcode`}
        label="Postcode"
        onChange={(newVal) => {
          onSetPostcode(formatPostcode(newVal.target.value));
        }}
      />
    </>
  );
};
