import * as Sentry from "@sentry/react";
import axios from "axios";

import { confirmPurchaseIntent } from "./confirmPurchaseIntent";
import { createEstateAgent } from "../../admin/service/AdminApiController/createEstateAgent";
import { createOrUpdateCustomer } from "./createOrUpdateCustomer";
import { createPurchaseIntent } from "./createPurchaseIntent";
import { createTransaction } from "./createTransaction";
import { fetchCurrentUser } from "./fetchCurrentUser";
import { fetchPaymentIntent } from "./fetchPaymentIntent";
import { fetchTransactionEventsTimeline } from "./fetchTransactionEventsTimeline";
import { fetchUser } from "./fetchUser";
import { findAddress } from "./findAddress";
import { findAdminConfig } from "../../admin/service/AdminApiController/findAdminConfig";
import { findAllTransactions } from "../../admin/service/AdminApiController/findAllTransactions";
import { findAllUsers } from "../../admin/service/AdminApiController/findAllUsers";
import { findLatestTransactionByUserId } from "./findLatestTransactionByUserId";
import { findProperties } from "./findProperties";
import { findPurchaseIntent } from "./findPurchaseIntent";
import { findTransaction } from "./findTransaction";
import { findTransactionByPurchaseIntentId } from "./findTransactionByPurchaseIntentId";
import { findTransactionsByPurchaseIntentUserId } from "./findTransactionsByPurchaseIntentUserId";
import { findTransactionsByUserId } from "./findTransactionsByUserId";
import { handleAxiosError } from "./handleAxiosError";
import { PurchaseIntentFormState } from "../../store/PurchaseIntentForm/purchaseIntentForm";
import { requestCallback } from "./requestCallback";
import { signIn } from "./signIn";
import { signOut } from "./signOut";
import { signUp } from "./signUp";
import { updateAdminPurchaseIntent } from "../../admin/service/AdminApiController/updatePurchaseIntent";
import { updateAdminTransaction } from "../../admin/service/AdminApiController/updateTransaction";
import { updateAdminUser } from "../../admin/service/AdminApiController/updateUser";
import { updatePurchaseIntent } from "./updatePurchaseIntent";
import { assignLawyerGroupTransaction } from "../../admin/service/AdminApiController/assignLawyerGroupInTransaction";
import { updatePurchaseIntentSignificantDates } from "./updatePurchaseIntentSignificantDates";
import { updateTransaction } from "./updateTransaction";
import { findAllLawyerGroups } from "../../admin/service/AdminApiController/findAllLawyerGroups";
import { updateTransactionTags } from "./updateTransactionTags";
import { findEstateAgent } from "../../admin/service/AdminApiController/findEstateAgent";
import { verifyRecaptcha } from "./verifyRecaptcha";
import { verifyUser } from "./verifyUser";

import { API_BASE_URL } from "../../constants";
import { AdminUser } from "../../admin/pages/UserDetails/types";
import {
  AdminTransaction,
  PurchaseIntent,
} from "../../admin/pages/TransactionDetails/types";
import { updateEstateAgentTransaction } from "../../admin/service/AdminApiController/updateEstateAgentInTransaction";
import { findAllEstateAgents } from "../../admin/service/AdminApiController/findAllEstateAgents";
import { orderSearches } from "../../admin/service/AdminApiController/orderSearches";
import { components } from "@shared/types.d";
import { TransactionFormState } from "../../store/TransactionForm/transactionForm";
import { fetchTransactionEvents } from "./fetchTransactionEvents";
import { updateEstateAgent } from "../../admin/service/AdminApiController/updateEstateAgent";
import { findAllEstateAgentBrands } from "../../admin/service/AdminApiController/findAllEstateAgentBrands";
import { updateEstateAgentBrand } from "../../admin/service/AdminApiController/updateEstateAgentBrand";
import { createEstateAgentBrand } from "../../admin/service/AdminApiController/createEstateAgentBrand";
import { deleteEstateAgentBrand } from "../../admin/service/AdminApiController/deleteEstateAgentBrand";
import { findAllLawFirms } from "../../admin/service/AdminApiController/findAllLawFirms";
import { findLawFirm } from "../../admin/service/AdminApiController/findLawFirm";
import { findLawyerGroup } from "../../admin/service/AdminApiController/findLawyerGroup";
import { createLawFirm } from "../../admin/service/AdminApiController/createLawFirm";
import { updateLawFirm } from "../../admin/service/AdminApiController/updateLawFirm";
import { updateReferralCodeInTransaction } from "./updateReferralCodeInTransaction";
import { validateReferralCode } from "./validateReferralCode";
import { updateLawyerGroup } from "../../admin/service/AdminApiController/updateLaywerGroup";
import { findAllTransactionsGroups } from "../../admin/service/AdminApiController/findAllTransactionsGroups";
import { sendEstateAgentThirdfortEmail } from "../../admin/service/AdminApiController/sendEstateAgentThirdfortEmail";
import { createTransactionsGroup } from "../../admin/service/AdminApiController/createTransactionsGroup";
import { findTransactionsGroup } from "../../admin/service/AdminApiController/findTransactionsGroup";
import { findUserByEmail } from "../../admin/service/AdminApiController/findUserByEmail";
import { findCompanyByNumber } from "../../admin/service/AdminApiController/findCompanyByNumber";
import { createActiveCustomer } from "../../admin/service/AdminApiController/createActiveCustomer";
import { updateUser } from "./updateUser";

// TODO: sort out types in project. Find a place for these start changing existing types to these.
type PurchaseIntentInput =
  components["schemas"]["PurchaseIntentRequestBodyDto"];
type Buyer = PurchaseIntentInput["buyers"][0];

export const ApiController = {
  confirmPurchaseIntent,
  createEstateAgent,
  createEstateAgentBrand,
  createLawFirm,
  createOrUpdateCustomer,
  createPurchaseIntent,
  createTransaction,
  createTransactionsGroup,
  createActiveCustomer,
  deleteEstateAgentBrand,
  fetchCurrentUser,
  fetchPaymentIntent,
  fetchTransactionEvents,
  fetchTransactionEventsTimeline,
  fetchUser,
  findAddress,
  findAdminConfig,
  findAllEstateAgents,
  findAllLawFirms,
  findAllLawyerGroups,
  findAllTransactions,
  findAllTransactionsGroups,
  findAllUsers,
  findEstateAgent,
  findAllEstateAgentBrands,
  findCompanyByNumber,
  findLatestTransactionByUserId,
  findLawFirm,
  findLawyerGroup,
  findProperties,
  findPurchaseIntent,
  findTransaction,
  findTransactionsGroup,
  findTransactionByPurchaseIntentId,
  findTransactionsByPurchaseIntentUserId,
  findTransactionsByUserId,
  findUserByEmail,
  orderSearches,
  requestCallback,
  sendEstateAgentThirdfortEmail,
  signIn,
  signOut,
  signUp,
  updateAdminPurchaseIntent,
  updateAdminTransaction,
  updateAdminUser,
  updateEstateAgentBrand,
  updateEstateAgentTransaction,
  updatePurchaseIntent,
  updatePurchaseIntentSignificantDates,
  updateReferralCodeInTransaction,
  assignLawyerGroupTransaction,
  updateEstateAgent,
  updateLawFirm,
  updateLawyerGroup,
  updateTransaction,
  updateTransactionTags,
  updateUser,
  validateReferralCode,
  verifyRecaptcha,
  verifyUser,
};

export const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to check API_BASE_URL before every request
api.interceptors.request.use(
  (config) => {
    if (API_BASE_URL.includes("https://www.propelr.co.uk/api")) {
      const errorMessage =
        "Error: API_BASE_URL contains the placeholder '_VITE_API_BASE_URL_'. Please set the correct base URL.";

      Sentry.captureException(errorMessage);
      console.error(errorMessage);
      return Promise.reject(new Error(errorMessage));
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Error handling interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("error", JSON.stringify(error));
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    } else {
      console.error("Non-Axios error:", error);
    }
    return Promise.reject(error);
  },
);

export const transformTransactionForApi = (
  transaction: TransactionFormState,
) => {
  const sellers = [
    transaction.primarySeller,
    ...transaction.property.additionalSellers,
  ];

  const transformedSellers = sellers.map((seller) => ({
    first_name: seller.firstName,
    middle_name: seller.middleName,
    last_name: seller.lastName,
    email: seller.email,
    phone_number: seller.mobileNumber,
    person_id: seller.personId,
    terms_and_conditions: seller.termsAndConditions || true,
    primary_customer: seller.primaryCustomer,
    active: true,
    customer_type: seller.customerType,
    correspondence_address: {
      line_1: seller.correspondenceAddress.line_1,
      line_2: seller.correspondenceAddress.line_2,
      line_3: seller.correspondenceAddress.line_3,
      post_town: seller.correspondenceAddress.post_town,
      country: seller.correspondenceAddress.country,
      postcode: seller.correspondenceAddress.postcode,
      county: seller.correspondenceAddress.county,
      uprn: seller.correspondenceAddress.uprn,
      district: seller.correspondenceAddress.district,
      building_number: seller.correspondenceAddress.building_number,
      sub_building_name: seller.correspondenceAddress.sub_building_name,
      building_name: seller.correspondenceAddress.building_name,
      premise: seller.correspondenceAddress.premise,
      organisation_name: seller.correspondenceAddress.organisation_name,
      po_box: seller.correspondenceAddress.po_box,
    },
  }));

  const tenure =
    transaction.property.tenureOption === "Other / Not Sure"
      ? transaction.property.tenureDetails
      : transaction.property.tenureOption;

  return {
    sellers: transformedSellers,
    property_id: {
      line_1: transaction.property.propertyAddress.line_1,
      line_2: transaction.property.propertyAddress.line_2,
      line_3: transaction.property.propertyAddress.line_3,
      post_town: transaction.property.propertyAddress.post_town,
      country: transaction.property.propertyAddress.country,
      postcode: transaction.property.propertyAddress.postcode,
      county: transaction.property.propertyAddress.county,
      uprn: transaction.property.propertyAddress.uprn,
      district: transaction.property.propertyAddress.district,
      building_number: transaction.property.propertyAddress.building_number,
      sub_building_name: transaction.property.propertyAddress.sub_building_name,
      building_name: transaction.property.propertyAddress.building_name,
      premise: transaction.property.propertyAddress.premise,
      organisation_name: transaction.property.propertyAddress.organisation_name,
      po_box: transaction.property.propertyAddress.po_box,
      tenure,
      _id: transaction.property._id,
    },
    onward_purchase: transaction.property.onwardPurchase,
    has_mortgage: transaction.property.hasMortgage,
    asking_price: parseFloat(
      transaction.property.askingPrice.replace(/,/g, ""),
    ),
    _id: transaction._id,
  };
};

export const transformPurchaseIntentForApi = (
  purchaseIntent: PurchaseIntentFormState,
): PurchaseIntentInput => {
  const property = purchaseIntent.property;
  const funding = property.funding;
  if (
    !property.property_id ||
    !property.ownershipType ||
    !property.mortgageRequiredForPurchase ||
    !funding.gifts
  ) {
    throw new Error("Something wrong with the validation");
  }

  const buyersFromForm = [
    purchaseIntent.primaryBuyer,
    ...property.additionalBuyers,
  ];

  const buyers: Buyer[] = buyersFromForm.map((buyer) => {
    const giftors = property.giftors.map((giftor) => {
      if (!giftor.relationship) {
        throw new Error("Giftor relationship is required");
      }

      return {
        amount: parseFloat(giftor.amount.replace(/,/g, "")),
        email: giftor.email,
        first_name: giftor.firstName,
        middle_name: giftor.middleName,
        last_name: giftor.lastName,
        phone_number: giftor.mobileNumber,
        relationship: giftor.relationship,
        correspondence_address: giftor.correspondenceAddress,
        person_id: giftor.personId,
      };
    });

    const transformedBuyer: Buyer = {
      first_name: buyer.firstName,
      middle_name: buyer.middleName,
      last_name: buyer.lastName,
      email: buyer.email,
      phone_number: buyer.mobileNumber,
      person_id: buyer.personId,
      terms_and_conditions: buyer.termsAndConditions || true,
      correspondence_address: {
        line_1: buyer.correspondenceAddress.line_1,
        line_2: buyer.correspondenceAddress.line_2,
        line_3: buyer.correspondenceAddress.line_3,
        post_town: buyer.correspondenceAddress.post_town,
        country: buyer.correspondenceAddress.country,
        postcode: buyer.correspondenceAddress.postcode,
        county: buyer.correspondenceAddress.county,
        uprn: buyer.correspondenceAddress.uprn,
        district: buyer.correspondenceAddress.district,
        building_number: buyer.correspondenceAddress.building_number,
        sub_building_name: buyer.correspondenceAddress.sub_building_name,
        building_name: buyer.correspondenceAddress.building_name,
        premise: buyer.correspondenceAddress.premise,
        organisation_name: buyer.correspondenceAddress.organisation_name,
        po_box: buyer.correspondenceAddress.po_box,
      },
      giftors: buyer.primaryCustomer ? giftors : [],
      active: true,
      customer_type: property.customerType as
        | "individual_owner"
        | "representative",
      primary_customer: buyer.primaryCustomer,
    };

    return transformedBuyer;
  });

  return {
    buyers,
    property_id: property.property_id,
    ownership_type: property.ownershipType,
    agreed_price: parseFloat(property.agreedPrice.replace(/,/g, "")),
    mortgage_required_for_purchase: property.mortgageRequiredForPurchase,
    mortgage_provider: property.mortgageProvider,
    purchase_dependent_on_sale: property.purchaseDependentOnSale || false,
    first_time_buyer: property.firstTimeBuyer || false,
    savings_from_employment: funding.savingsFromEmployment || false,
    gifts: funding.gifts,
    investment_portfolio: funding.investmentPortfolio || false,
    remortgage: funding.remortgage || false,
    inheritance: funding.inheritance || false,
    divorce_settlement: funding.divorceSettlement || false,
    pension_lump_sum_withdrawal: funding.pensionLumpSumWithdrawal || false,
    sale_of_shares: funding.saleOfShares || false,
    directors_loans_dividends: funding.directorsLoansDividends || false,
    other_funding: funding.otherFundingOption || "",
    other_funding_comments: funding.otherFundingComments,
  };
};

export const transformAdminUserForApi = (user: AdminUser) => {
  return {
    first_name: user.first_name,
    middle_name: user.middle_name,
    last_name: user.last_name,
    email: user.email,
    phone_number: user.phone_number || user.profile?.phone_number,
    active: true,
    type: "customer",
    correspondence_address: {
      line_1: user.profile?.correspondence_address.line_1,
      line_2: user.profile?.correspondence_address.line_2,
      line_3: user.profile?.correspondence_address.line_3,
      post_town: user.profile?.correspondence_address.post_town,
      country: user.profile?.correspondence_address.country,
      postcode: user.profile?.correspondence_address.postcode,
      county: user.profile?.correspondence_address.county,
      uprn: user.profile?.correspondence_address.uprn,
      district: user.profile?.correspondence_address.district,
      building_number: user.profile?.correspondence_address.building_number,
      sub_building_name: user.profile?.correspondence_address.sub_building_name,
      building_name: user.profile?.correspondence_address.building_name,
      premise: user.profile?.correspondence_address.premise,
      organisation_name: user.profile?.correspondence_address.organisation_name,
      po_box: user.profile?.correspondence_address.po_box,
    },
  };
};

export const transformAdminTransactionForApi = (
  transaction: AdminTransaction,
) => {
  const transformedData = {
    sellers: transaction.sellers.map((seller) => ({
      first_name: seller.user.first_name,
      middle_name: seller.user.middle_name || "",
      last_name: seller.user.last_name,
      email: seller.user.email,
      phone_number:
        seller.user.phone_number || seller.user.profile?.phone_number,
      terms_and_conditions: seller.terms_and_conditions || true,
      primary_customer: seller.primary_customer,
      customer_type: seller.customer_type,
    })),
    property_id: {
      line_1: transaction.property_id.address.line_1,
      line_2: transaction.property_id.address.line_2,
      line_3: transaction.property_id.address.line_3,
      post_town: transaction.property_id.address.post_town,
      country: transaction.property_id.address.country,
      postcode: transaction.property_id.address.postcode,
      county: transaction.property_id.address.county,
      uprn: transaction.property_id.address.uprn,
      district: transaction.property_id.address.district,
      building_number: transaction.property_id.address.building_number,
      sub_building_name: transaction.property_id.address.sub_building_name,
      building_name: transaction.property_id.address.building_name,
      premise: transaction.property_id.address.premise,
      organisation_name: transaction.property_id.address.organisation_name,
      po_box: transaction.property_id.address.po_box,
      tenure: transaction.property_id.tenure,
    },
    onward_purchase: transaction.onward_purchase,
    has_mortgage: transaction.has_mortgage,
    asking_price: transaction.asking_price,
    lawyer_group: transaction.lawyer_group,
    _id: transaction._id,
  };
  return transformedData;
};

export const transformAdminPurchaseIntentForApi = (
  purchaseIntent: PurchaseIntent,
) => {
  const transformedData = {
    buyers: purchaseIntent.buyers.map((buyer) => ({
      first_name: buyer.user.first_name,
      middle_name: buyer.user.middle_name,
      last_name: buyer.user.last_name,
      email: buyer.user.email,
      phone_number: buyer.user.phone_number || buyer.user.profile?.phone_number,
      terms_and_conditions: buyer.terms_and_conditions || true,
      giftors: buyer.giftors
        ? buyer.giftors.map((giftor) => ({
            amount: giftor.amount,
            email: giftor.email,
            first_name: giftor.first_name,
            middle_name: giftor.middle_name,
            last_name: giftor.last_name,
            phone_number: giftor.phone_number,
            relationship: giftor.relationship,
          }))
        : undefined,
      customer_type: buyer.customer_type,
      primary_customer: buyer.primary_customer,
    })),
    _id: purchaseIntent._id,
    property_id: purchaseIntent.property_id,
    ownership_type: purchaseIntent.ownership_type,
    agreed_price: purchaseIntent.agreed_price,
    mortgage_required_for_purchase:
      purchaseIntent.mortgage_required_for_purchase,
    mortgage_provider: purchaseIntent.mortgage_provider,
    purchase_dependent_on_sale: purchaseIntent.purchase_dependent_on_sale,
    first_time_buyer: purchaseIntent.first_time_buyer,
    savings_from_employment: purchaseIntent.funding.savings_from_employment,
    gifts: purchaseIntent.funding.gifts,
    investment_portfolio: purchaseIntent.funding.investment_portfolio,
    remortgage: purchaseIntent.funding.remortgage,
    inheritance: purchaseIntent.funding.inheritance,
    divorce_settlement: purchaseIntent.funding.divorce_settlement,
    pension_lump_sum_withdrawal:
      purchaseIntent.funding.pension_lump_sum_withdrawal,
    sale_of_shares: purchaseIntent.funding.sale_of_shares,
    directors_loans_dividends: purchaseIntent.funding.directors_loans_dividends,
    other_funding: purchaseIntent.funding.other_funding,
    other_funding_comments: purchaseIntent.other_funding_comments,
  };
  return transformedData;
};
