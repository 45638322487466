import { useState } from "react";
import validator from "validator";
import { Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";

import ErrorText from "@/foundation/Typography/ErrorText";
import { Button } from "@/components/Button/Button";
import { Modal } from "../../../components/Modal/Modal";
import { ApiController } from "../../../service/ApiController";
import { LawFirm } from "../../../types";
import { Input } from "../../../components/Formik/Input/Input";
import { AddressInputs } from "../../../components/Form/DetailsForm/AddressLookup/AddressInputs";
import { refineMobileNumber } from "../../../schema/helpers";

interface LawFirmModalProps {
  onClose: () => void;
  onSaved: () => void;
  lawFirm?: LawFirm;
}

const lawFirmForm = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  email: z.string().email().optional(),
  phone_number: z.string().refine(refineMobileNumber, {
    message: "Invalid mobile number",
  }),
  address: z.object({
    line_1: z.string().min(1, { message: "Address line 1 is required" }),
    line_2: z.string().optional(),
    line_3: z.string().optional(),
    post_town: z.string().min(1, { message: "Post town is required" }),
    country: z.string().min(1, { message: "Country is required" }),
    postcode: z
      .string()
      .min(1, { message: "Postcode is required" })
      .refine((str) => validator.isPostalCode(str, "GB"), {
        message: "Postcode is not valid",
      }),
    organisation_name: z
      .string()
      .min(1, { message: "Organisation name is required" }),
  }),
});

const lawFirmInitialValues = {
  name: "",
  phone_number: "",
  address: {
    line_1: "",
    line_2: "",
    line_3: "",
    post_town: "",
    country: "",
    postcode: "",
    organisation_name: "",
  },
};

type LawFirmForm = z.infer<typeof lawFirmForm>;

export const LawFirmModal = ({
  onClose,
  onSaved,
  lawFirm,
}: LawFirmModalProps) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onSaveLawFirm = async (newLawFirm: LawFirmForm) => {
    try {
      setError("");
      setLoading(true);

      if (lawFirm) {
        await ApiController.updateLawFirm(lawFirm._id, newLawFirm);
      } else {
        await ApiController.createLawFirm(newLawFirm);
      }

      onSaved();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("There was an error saving the law firm");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={lawFirm || lawFirmInitialValues}
      validate={withZodSchema(lawFirmForm)}
      onSubmit={onSaveLawFirm}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Modal title="Law Firm" hideSaveButton cancelButtonFunction={onClose}>
          <>
            <div className="mb-[8px]">
              <Input fieldName="name" label="Law Firm Name" />
            </div>
            <div className="mb-[8px]">
              <Input
                fieldName="email"
                label="Law Firm Email (optional)"
                onChange={(e) =>
                  setFieldValue(
                    "email",
                    e.target.value ? e.target.value : undefined,
                  )
                }
              />
            </div>
            <div className="mb-[8px]">
              <Input fieldName="phone_number" label="Law Firm Phone Number" />
            </div>
            <AddressInputs
              namespace="address"
              addressTitle="Law firm address"
              addressDescription="Please enter the law firm address"
              cardAddressTitle="Law firm address"
            />
            <div className="mb-[8px]">
              <Input
                fieldName="address.organisation_name"
                label="Law Firm Address Organisation Name"
              />
            </div>
            <div className="mt-[32px]">
              {error && <ErrorText>{error}</ErrorText>}
              <Button
                variant="primary"
                size="full"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={loading}
              >
                Save
              </Button>
            </div>
          </>
        </Modal>
      )}
    </Formik>
  );
};
