import * as Sentry from "@sentry/react";

import { api } from "../../../service/ApiController";

export const sendEstateAgentThirdfortEmail = async (
  transactionId: string,
): Promise<void> => {
  try {
    await api.post(`/thirdfort/thirdfort-requestor-email/${transactionId}`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
