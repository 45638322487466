import * as Sentry from "@sentry/react";

export const initializeSentry = async () => {
  try {
    // This dynamic import is necessary to avoid the env variables being populated at build time when they are not yet available. They only become available after Docker has retrived them from AWS and then populated them through a find and replace operation when the container is started.
    const { NODE_ENV, SENTRY_DSN, RELEASE_VERSION } = await import(
      "./constants.ts"
    );

    // If environment is Staging or Production, initialize Sentry
    if (NODE_ENV === "staging" || NODE_ENV === "production") {
      Sentry.init({
        environment: NODE_ENV,
        dsn: SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
          "localhost",
          "https://www.propelr.co.uk",
          "https://staging.propelr.co.uk",
        ],
        release: "borneo-frontend@" + RELEASE_VERSION,
        replaysSessionSampleRate: 0.5,
        replaysOnErrorSampleRate: 1.0,
        debug: false,
        autoSessionTracking: true,
      });

      console.log(`NODE_ENV is ${NODE_ENV}. Sentry initialized.`);
    } else {
      console.log(`NODE_ENV is ${NODE_ENV}. Sentry not initialized.`);
    }
  } catch (error) {
    console.error("Error initializing Sentry:", error);
  }
};
