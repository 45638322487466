import Typography from "@/foundation/Typography/Typography";
import { formatCurrency } from "@shared/utils";

export type PaymentSummaryProps = {
  totalAmount: number;
  vatOrderAmount: number;
  subtotalOrderAmount: number;
  verifiedReferralCode: string;
};

export const PaymentSummary = ({
  totalAmount,
  vatOrderAmount,
  subtotalOrderAmount,
  verifiedReferralCode,
}: PaymentSummaryProps) => (
  <>
    <div className="flex justify-between mb-[18px] text-ui-black-75">
      <Typography type="span" variant="sm" weight="regular">
        Subtotal
      </Typography>

      <Typography type="span" variant="sm" weight="regular">
        {formatCurrency(subtotalOrderAmount)}
      </Typography>
    </div>

    <div className="flex justify-between text-ui-black-75">
      <Typography type="span" variant="sm" weight="regular">
        VAT
      </Typography>

      <Typography type="span" variant="sm" weight="regular">
        {formatCurrency(vatOrderAmount)}
      </Typography>
    </div>

    <hr className="my-[22px] border-t-2 border-brand-heavy-warmth-100" />

    {verifiedReferralCode && (
      <>
        <div className="flex justify-between text-ui-black-75">
          <Typography type="span" variant="sm" weight="regular">
            Discount
          </Typography>

          <Typography type="span" variant="sm" weight="regular">
            - {formatCurrency(totalAmount)}
          </Typography>
        </div>
        <hr className="my-[22px] border-t-2 border-brand-heavy-warmth-100" />
      </>
    )}

    <div className="flex justify-between">
      <Typography type="span" variant="xl" weight="regular">
        Total
      </Typography>

      <Typography type="span" variant="xl" weight="regular">
        {verifiedReferralCode ? formatCurrency(0) : formatCurrency(totalAmount)}
      </Typography>
    </div>
  </>
);
