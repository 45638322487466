import { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { AxiosError } from "axios";

import { Button } from "@/components/Button/Button";
import { ApiController } from "../../../service/ApiController";
import {
  PersonalDetailsWithAddressSchema,
  PersonalDetailsWithAddressState,
} from "@schema";
import Typography from "@/foundation/Typography/Typography";
import { RepresentativeForm } from "./RepresentativeDetailsForm";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import { initialTransactionsGroupStateObj } from "./initialDetails";
import { FindUserForm } from "./FindUserForm";

interface AddRepresentativeDetailsProps {
  setDisplayRepresentativeDetailsForm: (value: boolean) => void;
  displayRepresentativeDetailsForm: boolean;
}

export const AddRepresentativeDetails = ({
  setDisplayRepresentativeDetailsForm,
  displayRepresentativeDetailsForm,
}: AddRepresentativeDetailsProps) => {
  const { setRepresentative } = useTransactionsGroupContext();

  const [isLoading, setIsLoading] = useState(false);

  const updateRepresentativeDetails = (
    values: PersonalDetailsWithAddressState,
  ) => {
    setRepresentative!(values);
    setDisplayRepresentativeDetailsForm(false);
  };

  const onSaveUser = async (
    values: PersonalDetailsWithAddressState,
    { setErrors }: FormikHelpers<PersonalDetailsWithAddressState>,
  ) => {
    try {
      await ApiController.findUserByEmail(values.email);
      // If it returns an user, set error message:
      setErrors({
        email:
          "User with this email already exists. Use the search bar to find user.",
      });
    } catch (error) {
      if (error instanceof AxiosError && error.status === 404) {
        setErrors({ email: "" });
        updateRepresentativeDetails({ ...values });
      } else {
        setErrors({
          email: "There was an error finding user by email",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full flex flex-col">
      <Formik
        initialValues={initialTransactionsGroupStateObj.representative}
        onSubmit={onSaveUser}
        validate={withZodSchema(PersonalDetailsWithAddressSchema)}
      >
        {(formik) => {
          return (
            <>
              <FindUserForm
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                updateRepresentativeDetails={updateRepresentativeDetails}
              />

              {displayRepresentativeDetailsForm ? (
                <RepresentativeForm />
              ) : (
                <button
                  className="underline cursor-pointer text-brand-heavy-teal-75 my-[16px] self-start"
                  onClick={() => setDisplayRepresentativeDetailsForm(true)}
                  type="button"
                >
                  <Typography
                    variant="base"
                    weight="regular"
                    type="span"
                    className="text-brand-heavy-teal-75"
                  >
                    Enter representative details manually
                  </Typography>
                </button>
              )}

              {displayRepresentativeDetailsForm && (
                <Button
                  size="2xl"
                  variant="primary"
                  type="button"
                  onClick={() => formik.handleSubmit()}
                  className="w-full md:w-[200px] h-[64px] my-[16px]"
                >
                  Save user
                </Button>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};
