import { Tenure, AssignedSelected } from "@shared/types";
import {
  Buyer,
  PurchaseIntent,
  Seller,
} from "../admin/pages/TransactionDetails/types";
import { ApiController } from "../service/ApiController";

export const isTransactionPaid = async (
  transactionId: string,
): Promise<boolean | undefined> => {
  try {
    const transactionEvents =
      await ApiController.fetchTransactionEvents(transactionId);
    return transactionEvents.some((event) => {
      return event.event_name === "payment_successful";
    });
  } catch {
    return undefined;
  }
};

export const getLatestPurchaseIntent = (
  purchaseIntents: PurchaseIntent[],
): PurchaseIntent | null => {
  return purchaseIntents.reduce(
    (acc, purchaseIntent) => {
      if (!acc) {
        return purchaseIntent;
      }

      return new Date(purchaseIntent.createdAt) > new Date(acc.createdAt)
        ? purchaseIntent
        : acc;
    },
    null as null | PurchaseIntent,
  );
};

export const getFirstTwoFullnames = (
  buyerSeller: Buyer[] | Seller[],
): string => {
  return buyerSeller.reduce((acc, curr, index) => {
    if (index > 1) return acc;
    if (!acc) {
      return `${curr.user.first_name} ${curr.user.last_name}`;
    }
    return `${acc}, ${curr.user.first_name} ${curr.user.last_name}`;
  }, "");
};

export const getVariant = (selected: AssignedSelected, isDisabled: boolean) => {
  if (selected.error) {
    return "error";
  } else if (isDisabled) {
    return "disabled";
  } else {
    return "enabled";
  }
};

export const isOtherTenure = (tenure: string) => {
  return (
    tenure !== Tenure.Freehold &&
    tenure !== Tenure.Leasehold &&
    tenure !== Tenure.ShareOfFreehold
  );
};
