import * as Sentry from "@sentry/react";

import { operations } from "@shared/types.d";
import { api } from "../../../service/ApiController";
import { AxiosError } from "axios";

type FindCompanyByNumber =
  operations["CompanyController_getCompanyByNumber"]["responses"]["200"]["content"]["application/json"];

export const findCompanyByNumber = async (
  number: string,
): Promise<FindCompanyByNumber> => {
  try {
    const response = await api.get(`/company/company-number/${number}`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data?.message);
    }
    throw error;
  }
};
