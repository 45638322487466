import { Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";

import { Modal } from "../../../components/Modal/Modal";
import { Input } from "../../../components/Formik/Input/Input";
import { useEstateAgentContext } from "../../context/EstateAgentProvider";
import { memo, useMemo, useRef } from "react";
import ErrorText from "@/foundation/Typography/ErrorText";
import { Button } from "@/components/Button/Button";

export const negotiatorForm = z.object({
  first_name: z.string().min(1),
  last_name: z.string().min(1),
  email: z.string().email(),
  phone_number: z.string().min(1),
});

interface EstateAgentNegotiatorModalProps {
  initialValues?: z.infer<typeof negotiatorForm>;
  errorMessage?: string;
  showDeleteButton?: boolean;
  disableDeleteButton?: boolean;
  onSubmit: (values: z.infer<typeof negotiatorForm>) => void;
  onClose: () => void;
  onDelete?: () => void;
}

const blankInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
};

export const EstateAgentNegotiatorModalCompoent = ({
  onSubmit,
  onClose,
  onDelete,
  errorMessage,
  showDeleteButton = false,
  disableDeleteButton = false,
  initialValues = blankInitialValues,
}: EstateAgentNegotiatorModalProps) => {
  const { loading } = useEstateAgentContext();

  const initialValuesRef = useRef(initialValues);

  const renderError = useMemo(
    () => <ErrorText>{errorMessage}</ErrorText>,
    [errorMessage],
  );

  return (
    <Formik
      initialValues={initialValuesRef.current}
      validate={withZodSchema(negotiatorForm)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Modal title="Negotiator" hideSaveButton cancelButtonFunction={onClose}>
          <>
            <div className="mb-[8px]">
              <Input fieldName="first_name" label="First Name" />
            </div>
            <div className="mb-[8px]">
              <Input fieldName="last_name" label="Last Name" />
            </div>
            <div className="mb-[8px]">
              <Input fieldName="email" label="Email" />
            </div>
            <div className="mb-[8px]">
              <Input fieldName="phone_number" label="Phone Number" />
            </div>
            <div className="mt-[32px]">
              {renderError}
              <Button
                variant="primary"
                size="full"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={loading}
              >
                Save
              </Button>
              {showDeleteButton && (
                <Button
                  variant="secondary"
                  size="full"
                  type="button"
                  disabled={disableDeleteButton || loading}
                  className="mt-[8px]"
                  onClick={onDelete}
                >
                  Delete
                </Button>
              )}
            </div>
          </>
        </Modal>
      )}
    </Formik>
  );
};

export const EstateAgentNegotiatorModal = memo(
  EstateAgentNegotiatorModalCompoent,
);
