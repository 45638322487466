import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

export type UpdateLawFirmResponse =
  paths["/api/law-firm/{id}"]["put"]["responses"]["200"]["content"]["application/json"];

export type UpdateLawFirmRequest =
  paths["/api/law-firm/{id}"]["put"]["requestBody"]["content"]["application/json"];

export const updateLawFirm = async (
  id: string,
  updatedLawFirm: UpdateLawFirmRequest,
): Promise<UpdateLawFirmResponse> => {
  try {
    const response = await api.put(`/law-firm/${id}`, updatedLawFirm);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof AxiosError && error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
};
