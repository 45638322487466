import * as Sentry from "@sentry/react";

import { operations } from "@shared/types.d";
import { api } from "../../../service/ApiController";

type FindUserByEmailResponse =
  operations["UserController_getUserByEmail"]["responses"]["200"]["content"]["application/json"];

export const findUserByEmail = async (
  email: string,
): Promise<FindUserByEmailResponse> => {
  try {
    const response = await api.get(`/users/email/${email}`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
