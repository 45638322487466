import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { Button } from "@/components/Button/Button";
import Typography from "@/foundation/Typography/Typography";

import { AssignEstateAgentBlock } from "../AssignEstateAgent/AssignEstateAgentBlock";
import { Table, TableProps } from "../Table/Table";
import { getAddressAsString } from "@shared/utils";
import { EstateAgent } from "../../../types";
import { useTransactionContext } from "../../context/TransactionProvider";

export const EstateAgentTab = () => {
  const { transaction } = useTransactionContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [estateAgent, setEstateAgent] = useState<EstateAgent>();
  const [officeDetails, setOfficeDetails] = useState<TableProps[]>([]);
  const [valuerDetails, setValuerDetails] = useState<TableProps[][]>([]);
  const [negotiatorDetails, setNegotiatorDetails] = useState<TableProps[][]>(
    [],
  );

  useEffect(() => {
    setEstateAgent(transaction?.estate_agent);
  }, [transaction]);

  useEffect(() => {
    if (estateAgent) {
      setOfficeDetails([
        { label: "Brand", value: `${estateAgent.brand}` },
        { label: "Name", value: `${estateAgent.office.name}` },
        { label: "Phone Number", value: `${estateAgent.office.phone_number}` },
        {
          label: "Address",
          value: `${estateAgent.office.address && getAddressAsString(estateAgent.office.address)}`,
        },
      ]);

      setValuerDetails(
        estateAgent.valuers.map((valuer) => [
          {
            label: "Primary Valuer",
            value: valuer.primary_valuer ? "Yes" : "No",
          },
          {
            label: "Name",
            value: `${valuer.first_name} ${valuer.last_name}`,
          },
          { label: "Phone Number", value: `${valuer.phone_number}` },
          { label: "Email", value: valuer.email },
        ]),
      );

      if (estateAgent.negotiators) {
        setNegotiatorDetails(
          estateAgent.negotiators?.map((negotiator) => [
            {
              label: "Name",
              value: `${negotiator.first_name} ${negotiator.last_name}`,
            },
            { label: "Phone Number", value: `${negotiator.phone_number}` },
            { label: "Email", value: negotiator.email },
          ]),
        );
      }
    } else {
      setOfficeDetails([]);
      setValuerDetails([]);
      setNegotiatorDetails([]);
    }
  }, [estateAgent]);

  return (
    <>
      {estateAgent ? (
        <Card
          title="Estate Agent"
          titleBackgroundColor="brand-heavy-warmth-25"
          titleColor="brand-heavy-teal-100"
          titleRightContent={
            <button
              className="text-brand-heavy-teal-100 underline"
              onClick={() => setIsModalOpen(true)}
            >
              Edit
            </button>
          }
        >
          <>
            <Typography
              variant="h4"
              weight="bold"
              className="text-brand-heavy-teal-100 mb-[20px]"
            >
              Office
            </Typography>
            {officeDetails.map(({ value, label }) => (
              <Table key={`office-${label}`} label={label} value={value} />
            ))}
            <Typography
              variant="h4"
              weight="bold"
              className="text-brand-heavy-teal-100 my-[20px]"
            >
              Valuers
            </Typography>
            {valuerDetails.map((valuer, index) => (
              <div className="mb-[20px]" key={`valuer-${index}`}>
                {valuer.map(({ value, label }) => (
                  <Table
                    key={`valuer-${label}-${index}`}
                    label={label}
                    value={value}
                  />
                ))}
              </div>
            ))}
            <Typography
              variant="h4"
              weight="bold"
              className="text-brand-heavy-teal-100 my-[20px]"
            >
              Negotiators
            </Typography>
            {negotiatorDetails?.map((negotiator, index) => (
              <div className="mb-[20px]" key={`negotiator-${index}`}>
                {negotiator.map(({ value, label }) => (
                  <Table
                    key={`negotiator-${label}-${index}`}
                    label={label}
                    value={value}
                  />
                ))}
              </div>
            ))}
          </>
        </Card>
      ) : (
        <Card>
          <div className="flex flex-col items-center">
            <Typography variant="lg" weight="semi-bold" className="mb-[16px]">
              There is no estate agent assigned to this transaction.
            </Typography>
            <div className="mt-[24px]">
              <Button
                type="button"
                onClick={() => setIsModalOpen(true)}
                variant="primary"
                size="lg"
              >
                Assign Estate Agent
              </Button>
            </div>
          </div>
        </Card>
      )}
      {isModalOpen && (
        <AssignEstateAgentBlock onCloseModal={() => setIsModalOpen(false)} />
      )}
    </>
  );
};
