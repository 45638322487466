import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";

export const TransactionsGroupDetails = () => {
  return (
    <>
      <AdminTitle title="Transactions Group" />
      Coming soon
    </>
  );
};
