import { useLocation } from "react-router-dom";
import { EstateAgentProvider } from "../../context/EstateAgentProvider";
import { EstateAgent } from "../../components/EstateAgentDetailsBlocks/EstateAgent";

export const EstateAgentDetails = () => {
  const location = useLocation();
  const estateAgentId = location.pathname.split("/")[3];

  return (
    <EstateAgentProvider estateAgentId={estateAgentId}>
      <EstateAgent />
    </EstateAgentProvider>
  );
};
