import * as Sentry from "@sentry/react";

import { api } from ".";
import { AxiosError } from "axios";

export const validateReferralCode = async (code: string): Promise<boolean> => {
  try {
    const response = await api.post("/referral-code/validate", { code });
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 404) {
        return false;
      } else {
        Sentry.captureException(error);
        throw new Error(
          error.response?.data?.message ||
            "An error occurred while validating code",
        );
      }
    }
    throw error;
  }
};
