import { Tabs } from "./types";
import {
  EstateAgentTab,
  PropertyTab,
  ThirdfortTab,
  SellersTab,
  PurchaseIntentTab,
  OrdersTab,
  SolicitorTab,
} from ".";
import { SearchesTab } from "./SearchesTab";
import { EventsTimelineTab } from "./EventsTimelineTab";

interface RenderTabContentProps {
  currentTab: Tabs;
}

export const RenderTabContent = ({
  currentTab,
}: RenderTabContentProps): React.ReactNode => {
  switch (currentTab) {
    case "property":
      return <PropertyTab />;
    case "thirdfort":
      return <ThirdfortTab />;
    case "sellers":
      return <SellersTab />;
    case "purchaseIntents":
      return <PurchaseIntentTab />;
    case "orders":
      return <OrdersTab />;
    case "solicitors":
      return <SolicitorTab />;
    case "estateAgent":
      return <EstateAgentTab />;
    case "searches":
      return <SearchesTab />;
    case "events":
      return <EventsTimelineTab />;
  }
};
