import * as Sentry from "@sentry/react";

import { api } from ".";
import { paths } from "@shared/types.d";

import { TransactionResponse } from "@shared/types";

export type CreateTransactionRequest =
  paths["/api/transaction"]["post"]["requestBody"]["content"]["application/json"];

export const createTransaction = async (
  transaction: CreateTransactionRequest,
): Promise<TransactionResponse> => {
  try {
    const response = await api.post("/transaction", transaction);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Create transaction failed");
  }
};
