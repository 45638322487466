import * as Sentry from "@sentry/react";

import { api, transformAdminUserForApi } from "../../../service/ApiController";
import { AdminUser } from "../../pages/UserDetails/types";

export const updateAdminUser = async (user: AdminUser): Promise<AdminUser> => {
  try {
    const transformedData = transformAdminUserForApi(user);
    const response = await api.put("/users", {
      fields: transformedData,
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Update user failed");
  }
};
